import {ColumnConfig} from "../../shared/personalization/table-config";
import {Observable} from "rxjs/Observable";
import {ColumnPossibleValue, ColumnQuickConfig, QuickFilterType} from "./quick-config.model";

export enum Direction {
  OUTBOUND = 1,
  INBOUND = 0,
  ALL = -1,
}

export class Message {
  constructor(public id: string, public channelId: string = '', public directionEnum: Direction, public finished: Date,
              public started: Date, public formatIn: string = '', public formatOut: string = '', public vdewType: string = '',
              public vdewVersion: string = '', public state: string = '', public acknowledgement: string = '', public sender: string = '',
              public correlationId: string = '', public messageId: string = '', public referenceId: string = '',
              public partner: string = '', public alternativeId: string = '', public clearingCode: string = '',
              public content: string = '', public additional1: string = '', public additional2: string = '',
              public additional3: string = '', public additional4: string = '') {
  }
}

export class MessageFilter {

  constructor(public dateFrom: string = '', public dateTo: string = '', public direction: string = '', public system: string = '',
              public type: string = '', public referenceId: string = '', public alternativeId: string = '', public processStates: string = '',
              public ackStates: string = '', public partner: string = '', public channel: string = '', public searchTerm: string = '',
              public messageId: string = '', public clearingCode: string = '', public archive: boolean = false,
              public filterClass: string = '', public paramTransparentSearch: string = '', public workListTitle: string = '',
              public incoming: string = '', public outgoing: string = '', public version: string = '', public fromDateUTC: string = '',
              public toDateUTC: string = '', public additional1: string = '', public additional2: string = '', public additional3: string = '', public additional4: string = '') {
  }
}

export class AdditionalColumnsProperties {
  public name: string;
  public value: string;
  public width: string;
}

export class MessageTableColumnConfig extends ColumnConfig {
  public columnCell: string;
  public columnClass: string;
  public sortable: boolean;
  public filterName?: string = undefined;
  public quickFilterType?: QuickFilterType = undefined;
  public quickConfig?: ColumnQuickConfig = undefined;
  public possibleValues?: Observable<ColumnPossibleValue[]> = undefined;
}

export class PersonalConfigDto {

  constructor(public id, public userName, public config) {
  }
}

export class Attribute {
  constructor(public attributeId: string,
              public actionId: string,
              public messageId: string,
              public name: string,
              public date: Date,
              public len: number,
              public type: string,
              public attachmentSize: number,
              public preview: string,
              public as2Message: boolean,
              public mailMessage: boolean) {
  }
}

export class AttributeValue {
  constructor(public attributeId: string,
              public actionId: string,
              public messageId: string,
              public value: string) {
  }
}

export class Action {
  constructor(public actionId: string,
              public messageId: string,
              public started: Date,
              public finished: Date,
              public type: string,
              public name: string,
              public className: string,
              public state: string) {
  }
}

export class FilterData {
  constructor(public id: string) {
  }
}

export class ClearingItem {
  public messageIds: string [];
  public code: string;
  public shorttext: string;
  public longtext: string;
}

export class ClearingItemWithoutMessageIds {
  public code: string;
  public shorttext: string;
  public longtext: string;
}

export class MessageClearingRequest {
  public immediateRestart: boolean;
  public clearingItem: ClearingItem;
}

export class AllMessagesClearingRequest {
  public immediateRestart: boolean;
  public clearingItemWithoutMessageIds: ClearingItemWithoutMessageIds;
  public messageFilter: MessageFilter;
}

export class ClearingCode {
  public code: string;
  public text: string;
}

export class Clearing {
  public messageId: string;
  public created: Date;
  public clearingCode: string;
  public shortText: string;
  public longText: string;
  public username: string;
}

export class ErrorModel {
  public errorId: string;
  public actionId: string;
  public messageId: string;
  public name: string;
  public stacktrace: string;
  public message: string;
}

export class MailMessage {
  public messageId: string;
  public fromAddress: string;
  public subject: string;
  public text: string;
  public attachments: Attachment[];
  public parentMessageId: string;
  public parentActionId: string;
  public parentAttributeId: string;
  public recipientsTO: string[];
  public recipientsCC: string [];
  public recipientsBCC: string [];
}

export class Attachment {
  public fileName: string;
  public fileExtension: string;
  public parentMessageID: string;
  public index: number;
  public fileSize: number;
}

export class AS2Attachment {
  public contentType: string;
  public contentTransferEncoding: string;
  public filename: string;
  public content: string;
  public index: number;
}

export class AS2Message {
  public userAgent: string;
  public date: Date;
  public messageId: string;
  public contentType: string;
  public as2Version: string;
  public recipientAddress: string;
  public toAddress: string;
  public fromAddress: string;
  public subject: string;
  public content: string;
  public b2bMessageId: string;
  public b2bActionId: string;
  public b2bAttributeId: string;
  public attachments: AS2Attachment[];
}

export class MessageIcon {
  public code: string;
  public icon: string;
  public type: string;
  public description: string;
}

