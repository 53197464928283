import {NgModule} from '@angular/core';
import {OverlayModule} from "@angular/cdk/overlay";
import {CdkTableModule} from "@angular/cdk/table";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import {MatCommonModule, MatNativeDateModule, NativeDateModule} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import {CustomPaginator} from "@next-level-integration-private/nli-i18n";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatBadgeModule} from "@angular/material/badge";
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
  imports: [MatCommonModule, MatToolbarModule, MatButtonModule, MatCheckboxModule, MatAutocompleteModule, MatCardModule, MatTooltipModule,
    MatDialogModule, MatIconModule, MatInputModule, MatMenuModule, MatRadioModule, MatSnackBarModule, MatTabsModule,
    MatListModule, OverlayModule, MatProgressSpinnerModule, MatTableModule, CdkTableModule, MatSelectModule,
    MatExpansionModule, MatPaginatorModule, MatSidenavModule, MatSortModule, MatStepperModule, MatDatepickerModule,
    MatNativeDateModule, MatMomentDateModule, MatButtonToggleModule, MatProgressBarModule, MatGridListModule, MatBadgeModule,
    MatBottomSheetModule, MatSlideToggleModule, DragDropModule, NativeDateModule],
  exports: [MatCommonModule, MatToolbarModule, MatButtonModule, MatCheckboxModule, MatAutocompleteModule, MatCardModule, MatTooltipModule,
    MatDialogModule, MatIconModule, MatInputModule, MatMenuModule, MatRadioModule, MatSnackBarModule, MatTabsModule,
    MatListModule, OverlayModule, MatProgressSpinnerModule, MatTableModule, CdkTableModule, MatSelectModule,
    MatExpansionModule, MatPaginatorModule, MatSidenavModule, MatSortModule, MatStepperModule, MatDatepickerModule,
    MatNativeDateModule, MatMomentDateModule, MatButtonToggleModule, MatProgressBarModule, MatGridListModule, MatBadgeModule,
    MatBottomSheetModule, MatChipsModule, MatSlideToggleModule, DragDropModule, NativeDateModule],
  providers: [
    {provide: MatPaginatorIntl, useClass: CustomPaginator}]
})
export class MaterialModule {
}
