import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {SystemSwitchService} from "../../../core/apis/system-switch.service";
import {SnackbarMessagesService} from "@next-level-integration/nli-core";
import {Observable} from "rxjs/Observable";
import {finalize} from "rxjs/operators";
import {MLocation, MLocationFilter} from "../../../core/models/system-switch.model";

export class MeloMaloDatasource implements DataSource<MLocation> {
  private readonly mLocationSubject = new BehaviorSubject<MLocation[]>([]);
  private readonly loadingSubject = new BehaviorSubject<boolean>(false);
  private newMLocations: MLocation[] = [];
  public totalRows = 0;
  public static DEFAULT_PAGE_SIZE = 100;
  public loading$ = this.loadingSubject.asObservable();
  public MAX_SEARCH_RESULT = 1000;

  constructor(private readonly systemSwitchService: SystemSwitchService,
              private readonly snackbarMessagesService: SnackbarMessagesService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<MLocation[]> {
    return this.mLocationSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.mLocationSubject.complete();
    this.loadingSubject.complete();
  }

  addNewMLocation(mLocation: MLocation) {
    this.newMLocations.push(mLocation);
  }

  loadMLocations(mLocationFilter: MLocationFilter, pageIndex = 0, pageSize = MeloMaloDatasource.DEFAULT_PAGE_SIZE, isSearch: boolean) {
    this.loadingSubject.next(true);
    this.systemSwitchService.getMLocationRouting(mLocationFilter, pageSize, pageIndex)
      .pipe(
        finalize(() => {
          this.loadingSubject.next(false);
          this.newMLocations = [];
        }))
      .subscribe(message => {
          let rows = [];

          if (message) {
            this.totalRows = message.totalElements;
            rows = message.content;
            if (!message.empty) {
              rows = [];
              message.content.forEach(element => rows.push(element));
            }
          } else {
            this.totalRows = 0;
          }

          if (this.newMLocations && this.newMLocations.length) {
            this.newMLocations.forEach(mLocation => rows.unshift(mLocation));
            this.totalRows += this.newMLocations.length;

            if (rows.length > pageSize) {
              rows = rows.slice(0, pageSize);
            }
          }

          if (this.totalRows === 0) {
            this.snackbarMessagesService.showSnackbar("systemSwitch.snackBar.failedMsg.noMLocationFound");
          }

          if (isSearch && (pageIndex === 0)) {
            if (this.totalRows >= this.MAX_SEARCH_RESULT) {
              this.snackbarMessagesService.showClosableSnackbar('systemSwitch.snackBar.warningTooManyResuts');
            }
          }

          this.mLocationSubject.next(rows);
        },
        error => {
          this.totalRows = 0;
          this.mLocationSubject.next([]);
          this.snackbarMessagesService.handleError(error, "systemSwitch.snackBar.failedMsg.noMLocationFound");
        }
      );
  }

  get data(): MLocation[] {
    return this.mLocationSubject.value;
  }
}
