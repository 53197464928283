import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  AS2Attachment,
  AS2Message,
  Attachment,
  Attribute,
  AttributeValue,
  MailMessage,
  Message,
  MessageFilter
} from "../../../../core/models/message.model";
import {MessageService} from "../../../../core/apis/message.service";
import {NliKeycloakAuthService} from "@next-level-integration-private/nli-keycloak-auth";
import {Router} from "@angular/router";
import {Constants} from "../../../../core/common/constants";
import {CopyService, DownloadFileService, SearchAndFormatService} from "@next-level-integration/nli-core";
import {ReplaySubject} from "rxjs/ReplaySubject";
import {Subscription} from "rxjs/Rx";
import {debounceTime} from "rxjs/operators";
import {Observable} from "rxjs/Observable";
import {forkJoin} from "rxjs/internal/observable/forkJoin";

@Component({
  selector: 'nli-message-monitor-professional-details',
  templateUrl: './professional-details.component.html',
  styleUrls: ['./professional-details.component.scss']
})
export class ProfessionalDetailsComponent implements OnInit, OnDestroy {
  @Input() messageDetailSubject: ReplaySubject<Message>;
  @Input() messageFilterSubject: ReplaySubject<MessageFilter>;
  @Input() sendMessageDetailSubject: ReplaySubject<Message>;
  @Input() attributesAllowed: string[];
  @Input() isFetchTechnicalAttributes = false;
  @Input() isShowActionButtons = false;

  isAttributeURL = false;
  isMessageMonitorRoleWrite = false;
  selectedMessage: Message = null;
  selectedAttribute: Attribute = null;
  attrValues: string[];
  readable = true;
  attributes: Attribute[] = [];
  selectedAttributeValue: AttributeValue = null;
  as2Message: AS2Message = null;
  mailMessage: MailMessage = null;
  isAS2Message = false;
  isMailMessage = false;
  isArchive = false;
  isShowDetails = false;
  subscriptions: Subscription[] = [];
  loading = true;
  search = '';
  enterIndex = 0;
  attrValuesInfo: string[];
  searchCountTotal = 0;

  constructor(private readonly messageService: MessageService,
              private readonly copyService: CopyService,
              private readonly searchAndFormatService: SearchAndFormatService,
              private readonly downloadFileService: DownloadFileService,
              private readonly nliKeycloakAuthService: NliKeycloakAuthService,
              private readonly router: Router,
              private cdr: ChangeDetectorRef) {
    this.isMessageMonitorRoleWrite = nliKeycloakAuthService.hasRole(Constants.B2B_ROLE_MESSAGE_MONITOR_WRITE);
  }

  ngOnInit() {
    if (this.messageFilterSubject) {
      this.subscriptions.push(this.messageFilterSubject
        .subscribe((messageFilter: MessageFilter) => {
          this.isArchive = messageFilter.archive;
        }));
    }
    this.subscriptions.push(this.messageDetailSubject
      .pipe(debounceTime(0))
      .subscribe(message => this.updateSelectedMessage(message)));
  }


  updateSelectedMessage(message: Message) {
    this.clearMessageDetails();
    this.loadMessageDetails(message);
  }

  formatMessageForReadability() {
    if (!this.readable) {
      this.replaceValue("'\n", 'g', "'");
    }
    if (this.readable) {
      this.replaceValue("'", 'g', "'\n");
    }
    this.resetAttrValues();
  }

  replaceValue(pattern: string, flags: string, replaceValue: string) {
    this.selectedAttributeValue.value = this.selectedAttributeValue.value.replace(new RegExp(pattern, flags), replaceValue);
  }

  downloadAttribute() {
    const fileName = this.selectedAttribute.name + "_" + this.selectedAttribute.messageId;
    let fileExtension = 'txt';
    if (this.selectedAttribute.name === 'B3P_VAT_BASE_MESSAGE') {
      fileExtension = 'pdf';
    }
    this.downloadFileService.downloadFile(this.messageService
      .downloadAttribute(this.selectedAttribute.messageId, this.selectedAttribute.actionId,
        this.selectedAttribute.attributeId, this.isArchive, Constants.PROFESSIONAL), fileName + '.' + fileExtension);
  }

  getMailAttachmentAttributeValue() {
    this.mailMessage.attachments.forEach((attachment: Attachment) => {
      this.messageService.downloadMailAttachment(this.selectedAttribute.messageId, this.selectedAttribute.actionId,
        this.selectedAttribute.attributeId, this.isArchive, attachment).subscribe((result: Blob) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          this.readableAndResetAttrValues(fileReader.result.toString());
        };
        fileReader.readAsText(result, 'iso-8859-1');
      });
    });
  }

  downloadMailAttachment() {
    if (this.isMailMessage && this.mailMessage !== null) {
      this.mailMessage.attachments.forEach((attachment: Attachment) => {
        this.downloadFileService.downloadFileWithTypeWithOnFinish(this.messageService
            .downloadMailAttachment(this.selectedAttribute.messageId, this.selectedAttribute.actionId,
              this.selectedAttribute.attributeId, this.isArchive, attachment),
          this.getFileName(attachment), this.getTextType(attachment.fileExtension),
          () => {
          });
      });
    }
  }

  getFileName(attachment: Attachment): string {
    // When send email as the gzip file, in message monitor,
    // we will allow to download txt/xml file instead of gzip file
    // Because in Backend already uncompress the content as txt/xml
    if ('gz' === attachment.fileExtension.toLowerCase()) {
      const fileTypes = ['.txt', '.xml'];
      if (this.isFileNameHasType(attachment.fileName, fileTypes[0])
        || this.isFileNameHasType(attachment.fileName, fileTypes[1])) {
        return attachment.fileName;
      }
      const indexOfDot = attachment.fileName.lastIndexOf('.');
      if (indexOfDot < 0) {
        return attachment.fileName + fileTypes[0];
      }
    }
    return attachment.fileName + "." + attachment.fileExtension;
  }

  isFileNameHasType(fileName: string, fileType: string): boolean {
    const indexOfDot = fileName.lastIndexOf('.');
    if (indexOfDot > 0) {
      const fileNameHasType = fileName.substr(indexOfDot, fileName.length);
      if (fileNameHasType.toLowerCase() === fileType.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  private getTextType(fileExtension: string): string {
    let textType = 'text/plain';
    if ('pdf' === fileExtension.toLowerCase()) {
      textType = 'text/pdf';
    }
    return textType;
  }

  getAS2AttachmentAttributeValue() {
    this.as2Message.attachments.forEach((attachment: AS2Attachment) => {
      this.messageService.downloadAS2Attachment(this.selectedAttribute.messageId, this.selectedAttribute.actionId,
        this.selectedAttribute.attributeId, this.isArchive, attachment).subscribe((result: Blob) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          this.readableAndResetAttrValues(fileReader.result.toString());
        };
        fileReader.readAsText(result, 'iso-8859-1');
      });
    });
  }

  downloadAS2Attachment() {
    if (this.isAS2Message && this.as2Message !== null) {
      this.as2Message.attachments.forEach((attachment: AS2Attachment) => {
        this.downloadFileService.downloadFile(this.messageService
          .downloadAS2Attachment(this.selectedAttribute.messageId, this.selectedAttribute.actionId,
            this.selectedAttribute.attributeId, this.isArchive, attachment), attachment.filename);
      });
    }
  }

  downloadAttachmentWithoutFetchingDetail() {
    if (!this.selectedAttribute || this.isShowDetails) {
      return;
    }

    if (this.isMailMessage) {
      this.mailDetail(this.selectedAttribute, true);
    } else if (this.isAS2Message) {
      this.as2Detail(this.selectedAttribute, true);
    } else {
      this.downloadAttribute();
    }
  }

  downloadAttachment() {
    this.downloadMailAttachment();
    this.downloadAS2Attachment();
  }

  clearMessageDetails() {
    this.attributes = [];
    this.selectedAttribute = null;
    this.selectedAttributeValue = null;
    this.attrValues = [];
  }

  loadMessageDetails(message: Message) {
    this.selectedMessage = message;
    this.emitNewMessage(message);
    if (this.selectedMessage !== null) {
      this.getAttributes();
    }
  }

  getAttributes() {
    const calls = this.getAttributesCalls();
    this.subscriptions.push(calls.subscribe(({professionalAttributes, technicalAttributes}) => {
      let finalAttributes = professionalAttributes;
      if (technicalAttributes) {
        finalAttributes = professionalAttributes.concat(technicalAttributes);
      }
      this.populateAttributes(finalAttributes);
      this.loading = false;
      this.cdr.detectChanges();
    }));
  }

  private getAttributesCalls() {
    let calls: Observable<any>;
    if (this.isFetchTechnicalAttributes) {
      calls = forkJoin({
        professionalAttributes: this.messageService.getProfessionalAttributes(this.selectedMessage.messageId, this.isArchive),
        technicalAttributes: this.messageService.getTechnicalAttributes(this.selectedMessage.messageId, null)
      });
    } else {
      calls = this.messageService.getProfessionalAttributes(this.selectedMessage.messageId, this.isArchive)
        .map(professionalAttributes => {
          return {professionalAttributes};
        });
    }
    return calls;
  }

  private populateAttributes(attributes: Attribute[]): void {
    if (attributes && attributes.length > 0) {
      if (this.attributesAllowed && this.attributesAllowed.length > 0) {
        attributes.filter(attribute => this.attributesAllowed.indexOf(attribute.attributeId) !== -1)
          .forEach(attribute => this.attributes.push(attribute));
      } else {
        attributes.forEach(attribute => this.attributes.push(attribute));
      }
      this.selectFirstRowIfAny();
    }
  }

  private selectFirstRowIfAny(): void {
    if (this.selectedAttribute) {
      return;
    }
    const firstItem: Attribute = this.attributes && this.attributes.length > 0 ? this.attributes[0] : null;
    if (firstItem !== null) {
      this.attributeRowSelected(firstItem);
    }
  }

  private fetchDetails(): void {
    const attribute = this.selectedAttribute;
    if (!attribute) {
      return;
    }

    this.attrValues = [];
    this.selectedAttributeValue = null;
    this.messageService.getAttributeValue(attribute.attributeId, attribute.messageId, attribute.actionId,
      this.isArchive).subscribe((attributeValue: AttributeValue) => {
      this.selectedAttributeValue = attributeValue;
      if (!this.enableASMail()) {
        this.readableAndResetAttrValues(atob(attributeValue.value));
      }
      if (this.isMailMessage) {
        this.mailDetail(attribute);
      } else if (this.isAS2Message) {
        this.as2Detail(attribute);
      }
      this.isAttributeURL = this.isValidHttpUrl(this.selectedAttributeValue.value);
    });
  }

  attributeRowSelected(attribute: Attribute) {
    this.isShowDetails = false;
    this.selectedAttribute = attribute;
    this.isAttributeURL = this.isValidHttpUrl(this.selectedAttribute.preview);
    this.isMailMessage = attribute.mailMessage;
    this.isAS2Message = attribute.as2Message;
  }

  showDetails(): void {
    this.isShowDetails = true;
    this.fetchDetails();
  }

  readableAndResetAttrValues(attributeValue: string) {
    this.readable = true;
    this.selectedAttributeValue.value = attributeValue;
    this.formatMessageForReadability();
    this.cdr.detectChanges();
  }

  resetAttrValues() {
    if (!this.selectedAttributeValue.value) {
      this.attrValues = [];
      this.attrValuesInfo = [];
    } else {
      this.attrValues = this.selectedAttributeValue.value.split(',');
      this.searchInputChange(this.search);
    }
  }

  enableASMail(): boolean {
    if (this.isAS2Message || this.isMailMessage) {
      return true;
    }
    return false;
  }

  copyMessageLink() {
    if (this.selectedMessage) {
      this.messageService.getCoherentMessageLink(this.selectedMessage.messageId)
        .subscribe(coherentMsgLink => {
          const messageId = encodeURIComponent(this.selectedMessage.messageId);
          this.copyService.copyToClipboard(this.getCurrentURL()
            + '?msgId=' + messageId + '&' + coherentMsgLink.substr(coherentMsgLink.indexOf("fromDate"), coherentMsgLink.length));
        });
    }
  }

  copyCoherentMessageLink() {
    if (this.selectedMessage) {
      this.messageService.getCoherentMessageLink(this.selectedMessage.messageId)
        .subscribe(coherenMsgLink => {
          this.copyService.copyToClipboard(this.getCurrentURL() + coherenMsgLink);
        });
    }
  }

  showCoherentMessage() {
    if (this.selectedMessage) {
      this.messageService.getCoherentMessageLink(this.selectedMessage.messageId)
        .subscribe(coherenMsgLink => {
          window.open(this.getCurrentURL() + coherenMsgLink + "&maximize");
          this.router.navigate([this.router.url], {
            skipLocationChange: true
          });
        });
    }
  }

  copyAttachmentContent() {
    let attachmentContent = '';
    this.attrValues.forEach((value, index) => {
      if (index !== 0) {
        attachmentContent += '\n';
      }
      attachmentContent += value;
    });

    this.copyService.copyToClipboard(attachmentContent);
  }

  private as2Detail(attribute: Attribute, isDownloadAndSkipDetail = false) {
    this.as2Message = null;
    this.messageService.getAs2Message(attribute.messageId, attribute.actionId, attribute.attributeId, this.isArchive)
      .subscribe((as2Message: AS2Message) => {
        this.as2Message = as2Message;
        if (isDownloadAndSkipDetail) {
          this.downloadAS2Attachment();
        } else {
          this.getAS2AttachmentAttributeValue();
        }
      });
  }

  private mailDetail(attribute: Attribute, isDownloadAndSkipDetail = false) {
    this.as2Message = null;
    this.mailMessage = null;
    this.messageService.getMailMessage(attribute.attributeId, attribute.messageId, attribute.actionId, this.isArchive)
      .subscribe((mailMessage: MailMessage) => {
        this.mailMessage = mailMessage;
        this.as2Message = {
          userAgent: '',
          date: new Date(),
          messageId: mailMessage.messageId,
          contentType: '',
          as2Version: '',
          recipientAddress: '',
          toAddress: mailMessage.recipientsTO.toString(),
          fromAddress: mailMessage.fromAddress,
          subject: mailMessage.subject,
          content: mailMessage.text,
          b2bMessageId: '',
          b2bActionId: '',
          b2bAttributeId: '',
          attachments: []
        };
        if (isDownloadAndSkipDetail) {
          this.downloadMailAttachment();
        } else {
          this.getMailAttachmentAttributeValue();
        }
      });
  }

  private emitNewMessage(message: Message) {
    if (this.sendMessageDetailSubject) {
      this.sendMessageDetailSubject.next(message);
    }
  }

  private getCurrentURL(): string {
    return window.location.href.split("?")[0];
  }

  private isValidHttpUrl(string): boolean {
    let url;
    try {
      url = new URL(string);
    } catch (e) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  searchInputChange(searchValue) {
    this.enterIndex = 0;
    this.searchCountTotal = 0;
    if (searchValue.length === 0) {
      if (!this.selectedAttributeValue.value) {
        this.attrValuesInfo = [];
      } else {
        this.attrValuesInfo = this.selectedAttributeValue.value.split(',');
      }
      return;
    }
    this.attrValues.forEach((value, index) => {
      this.attrValuesInfo[index] = this.searchAndFormatService.searchCaseInsensitiveAndFormat(searchValue, value, 'highlighted');
      this.searchCountTotal = this.searchCountTotal + this.searchAndFormatService.searchCount(searchValue, value, 'highlighted');
    });
  }

  searchData() {
    if (this.enterIndex < this.searchCountTotal) {
      this.enterIndex = this.searchAndFormatService.formatNextResult(this.search, this.enterIndex, 'highlighted', 'highlighted-orange');
    } else {
      this.enterIndex = this.searchAndFormatService.formatNextResult(this.search, 0, 'highlighted', 'highlighted-orange');
    }
  }

}
