import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'b2b-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  selectedFile: File;
  selectedFileName: string;
  @Input() acceptFileType = '*.*';
  @Input() form: FormGroup;
  @Output() complete = new EventEmitter<File>();

  constructor() {
  }

  ngOnInit() {
  }

  onSelectedFile(event) {
    this.selectedFile = <File>event.target.files[0];
    this.selectedFileName = null;
    if (this.selectedFile !== null) {
      this.selectedFileName = this.selectedFile.name;
    }
    this.complete.emit(this.selectedFile);
  }
}
