import {NgModule} from "@angular/core";
import {NLIDateFieldModule} from "@next-level-integration/nli-datefield-lib";
import {NliSearchBarModule} from "@next-level-integration/search-bar";
import {NliMenuModule} from "@next-level-integration/menu";
import {NliInputsModule} from "@next-level-integration/inputs";
import {SystemMessagesModule} from "@next-level-integration/system-messages";
import {AccountInfoModule} from "@next-level-integration/nli-account-info";
import {NliListErrorsModule} from "@next-level-integration/list-errors";
import {NliHotkeysModule} from "@next-level-integration/nli-hotkeys";
import {NliCoreModule} from "@next-level-integration/nli-core";

@NgModule({
  imports: [NLIDateFieldModule,
    NliSearchBarModule,
    NliMenuModule,
    NliInputsModule,
    AccountInfoModule,
    SystemMessagesModule,
    NliCoreModule,
    NliHotkeysModule,
    NliListErrorsModule],
  exports: [NLIDateFieldModule,
    NliSearchBarModule,
    NliMenuModule,
    NliInputsModule,
    AccountInfoModule,
    SystemMessagesModule,
    NliCoreModule,
    NliHotkeysModule,
    NliListErrorsModule],
  providers: []
})
export class LibrariesModule {
}
