<mat-sidenav-container autosize class="main-container" (backdropClick)="closeEndSideNav()">
  <mat-sidenav autosize class="sidenav" mode="side" opened="true" position="start" [disableClose]="true">
    <mat-nav-list class="nav-list">
      <a (click)="isExpanded = !isExpanded"
        class="header-side-nav white-text" title="{{'sideNav.expandMenu'| translate}}">
        <mat-list-item>
          <mat-icon mat-list-icon class="white-text">dehaze</mat-icon>
          <p *ngIf="isExpanded" matLine>{{'sideNav.header'| translate}}</p>
        </mat-list-item>
      </a>
      <mat-divider class="mb-1"></mat-divider>
      <a *ngIf="isDashboardRole && isCustomDashboard" [routerLink]="['customdashboard']" title="{{'sideNav.customdashboard'| translate}}">
        <mat-list-item routerLinkActive="active-link">
          <mat-icon mat-list-icon>home</mat-icon>
          <p *ngIf="isExpanded" class="menu-text" matLine
                                routerLinkActive="active-link-p">{{'sideNav.customdashboard'| translate}}</p>
        </mat-list-item>
      </a>
      <a *ngIf="isDashboardRole" [routerLink]="['dashboard']" title="{{'sideNav.dashboard'| translate}}">
        <mat-list-item routerLinkActive="active-link">
          <mat-icon mat-list-icon>dashboard</mat-icon>
          <p *ngIf="isExpanded" class="menu-text" matLine
                                routerLinkActive="active-link-p">{{'sideNav.dashboard'| translate}}</p>
        </mat-list-item>
      </a>
      <a *ngIf="isMessageMonitorRoleRead || isMessageMonitorRoleWrite" [routerLink]="[messageMonitorWeb]"
         title="{{'sideNav.messageMonitor'| translate}}">
        <mat-list-item routerLinkActive="active-link">
          <mat-icon mat-list-icon>dvr</mat-icon>
          <p *ngIf="isExpanded" class="menu-text" matLine
             routerLinkActive="active-link-p">{{'sideNav.messageMonitor'| translate}}</p>
        </mat-list-item>
      </a>
      <a *ngIf="isManualMarketRole" [routerLink]="['sendmanually']" title="{{'sideNav.sendManually'| translate}}">
        <mat-list-item routerLinkActive="active-link">
          <mat-icon mat-list-icon>send</mat-icon>
          <p *ngIf="isExpanded" class="menu-text" matLine
                                routerLinkActive="active-link-p">{{'sideNav.sendManually'| translate}}</p>
        </mat-list-item>
      </a>
      <a *ngIf="isMpidRoleRead || isMpidRoleWrite" [routerLink]="['marketpartneradmin']" title="{{'sideNav.marketPartnerAdmin'| translate}}">
        <mat-list-item routerLinkActive="active-link">
          <mat-icon mat-list-icon>account_circle</mat-icon>
          <p *ngIf="isExpanded" class="menu-text" matLine
                                routerLinkActive="active-link-p">{{'sideNav.marketPartnerAdmin'| translate}}</p>
        </mat-list-item>
      </a>
      <a *ngIf="isSystemSwitchRole" [routerLink]="['systemswitch']" title="{{'sideNav.systemSwitch'| translate}}">
        <mat-list-item routerLinkActive="active-link">
          <mat-icon class="rotate-90" mat-list-icon>publish</mat-icon>
          <p *ngIf="isExpanded" class="menu-text" matLine
                                routerLinkActive="active-link-p">{{'sideNav.systemSwitch'| translate}}</p>
        </mat-list-item>
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <div class="baseDiv">
    <app-header [showImage]="false">
      <div *ngIf="!this.isWorklist" class="dashboard-header toolbar-text hidden-xs">{{title | translate}}</div>
      <nli-worklist></nli-worklist>
    </app-header>
    <mat-sidenav-container class="right-content">
      <router-outlet></router-outlet>
    </mat-sidenav-container>
  </div>
  <mat-sidenav position="end" #endSideNav class="sidenav, error-sidenav" (keydown.escape)="closeEndSideNav()">
    <nli-list-errors></nli-list-errors>
    <nli-system-message-display [isActiveUserMessages]="activateUserMessages"
      [systemMessageProxyUrl]="systemMessagesPath"></nli-system-message-display>
  </mat-sidenav>
</mat-sidenav-container>
