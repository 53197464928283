export class MLocation {
  constructor(public location: string, public system: string, public client: string, public validFrom: Date,
              public validTo: Date, public balanceFrom: Date, public balanceTo: Date, public pending: boolean) {
  }
}

export class MLocationRequest {
  constructor(public location: string,
              public system: string,
              public client: string,
              public validFrom: Date | string,
              public validTo:  Date | string) {
  }
}

export class MLocationFilter {
  constructor(public query = '') {
  }
}
