<button mat-icon-button [matMenuTriggerFor]="personalizationMenu"  [matTooltip]="'personalization.menuTooltip' | translate">
                                        <mat-icon>settings</mat-icon>
                                    </button>
                                    <mat-menu #personalizationMenu="matMenu">
                                        <button mat-menu-item (click)="openEditTableDialog()">
                                            <mat-icon>filter_list</mat-icon>
                                            <span>{{'personalization.changeColumns' | translate}}</span>
                                        </button>
                                        <button mat-menu-item *ngIf="filterConfig" (click)="openEditFilterDialog()">
                                            <mat-icon>view_column</mat-icon>
                                            <span>{{'personalization.changeFilters' | translate}}</span>
                                        </button>
                                        <button (click)="startDownload()" mat-menu-item>
                                            <mat-icon>file_download</mat-icon>
                                            <span>{{'personalization.export' | translate}}</span>
                                        </button>
                                        <button mat-menu-item (click)="openUploadPersonalizationDialog()">
                                            <mat-icon>file_upload</mat-icon>
                                            <span>{{'personalization.import' | translate}}</span>
                                        </button>
                                    </mat-menu>
