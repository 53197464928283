<div class="quickconfig">
  <div class="upper-content" (mouseup)="$event.stopPropagation()" (click)="$event.stopPropagation()">
    <div class="sort-container">
      <!-- Title -->
      <label class="title">{{'messageMonitor.quickFilter.sort'| translate}}</label>
      <!-- 2 Filter-Buttons -->
      <div (click)="$event.stopPropagation();">
        <mat-button-toggle-group #group="matButtonToggleGroup"
                                 [value]="filter.asc === undefined?undefined:filter.asc?'asc':'desc'">
          <mat-button-toggle value="asc" (change)="toggleSort(true)" class="sort-toggle">
            <mat-icon class="sortIcon" color="accent" svgIcon="sort_up"></mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="desc" (change)="toggleSort(false)" class="sort-toggle">
            <mat-icon class="sortIcon" color="accent" svgIcon="sort_down"></mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>
  <div *ngIf="filter.filterType != FilterType.DATE" class="filter-container" (mouseup)="$event.stopPropagation()" (click)="$event.stopPropagation()">
    <!-- Searchbar -->
    <div (click)="$event.stopPropagation();" class="search-bar"
         [style.display]="filter.filterType == FilterType.SELECT?'none':''">
      <mat-form-field class="search-field gray-label-input" floatLabel="never">
        <input #searchInput autocomplete="off" type="text" matInput
               [(ngModel)]="searchTerm"
               placeholder="{{'messageMonitor.quickFilter.search'| translate}}">
        <mat-icon matSuffix color="accent" matTooltip="{{'messageMonitor.quickFilter.operatorInfo'| translate}}">
          info_outline
        </mat-icon>
      </mat-form-field>
    </div>
    <!-- Checkboxes for filtering-->
    <div #selectOptions class="values-list" *ngIf="filter.filterType != FilterType.EXPRESSION">
      <p style="font-weight: bold">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="onCheckAll($event, 'all')"
                      class="quickconfig-checkbox"
                      [checked]="allChecked">{{'messageMonitor.quickFilter.allButton'| translate}}</mat-checkbox>
      </p>
      <p *ngFor="let pv of filteredPossibleValues;">
        <mat-checkbox (click)="$event.stopPropagation()"
                      [ngClass]="{'no-value':pv.value === 'EMP'}"
                      [matTooltip]="pv.name"
                      (change)="onCheckChange($event, pv.value)"
                      [checked]="filter.values.includes(pv.value)"
                      [name]="pv.name | translate">
          {{pv.name | translate}}
        </mat-checkbox>
      </p>
    </div>
  </div>
  <div *ngIf="filter.filterType == FilterType.DATE" class="filter-container">
    <b2b-date-range-picker [selection]="filter.dateRange"
                           (selectionChanged)="dateSelected($event)"></b2b-date-range-picker>
  </div>
</div>


