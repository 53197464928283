import {DateRangePickerSelection} from "../../shared/components/date-range-picker/date-range-picker-selection";
import {MessageFilter, MessageTableColumnConfig} from "./message.model";

export class QuickConfig {
  columns: MessageTableColumnConfig[] = [];
}

export class ColumnQuickConfig {
  name: string;
  asc?: boolean;
  sortIndex?: number;
  filtered = false;
  filterType: QuickFilterType;
  values?: string [];
  expression?: string;
  dateRange?: DateRangePickerSelection = undefined;
}

export class ColumnPossibleValue {
  name: string;
  value: string;
}

export enum QuickFilterType {
  SELECT,
  EXPRESSION,
  DATE,
  SELECT_WITH_EXPRESSION
}

export function isFiltered(config: ColumnQuickConfig): boolean {
  return (config.values && config.values.length > 0)
    || (config.expression && config.expression.length > 0)
    || config.dateRange !== undefined;
}

export function isEmptyQuickConfig(config: ColumnQuickConfig): boolean {
  return config.asc === undefined
    && !isFiltered(config);
}

export function getSortIndex(quickConfig: QuickConfig, column: MessageTableColumnConfig): number {
  let index = 1;
  let exists = false;
  for (let i = 0; i < quickConfig.columns.length; i++) {
    const col = quickConfig.columns[i];
    if (col.columnCell === column.columnCell) {
      exists = col.quickConfig.asc !== undefined;
      break;
    }
    if (col.quickConfig.asc !== undefined) {
      index++;
    }
  }
  return index;
}

export function combineFilters(masterFilter: MessageFilter, quickFilter: QuickConfig): MessageFilter {
  const filter = {...masterFilter};
  quickFilter.columns.forEach(column => {
    const columnFilter = column.quickConfig;
    switch (column.quickFilterType) {
      case QuickFilterType.DATE:
        if (columnFilter.dateRange) {
          if (column.filterName === 'dateFrom') {
            const fromDate = columnFilter.dateRange.from.toDate();
            const from = new Date(fromDate);
            filter.fromDateUTC = String(fromDate);
            filter.dateFrom = from.toISOString();
            const toDate = columnFilter.dateRange.to.toDate();
            const to = new Date(toDate);
            filter.toDateUTC = String(toDate);
            filter.dateTo = to.toISOString();
          }
        }
        break;
      case QuickFilterType.SELECT:
        if (columnFilter.values && columnFilter.values.length === 1) {
          filter[column.filterName] = columnFilter.values[0];
        }
        break;
      case QuickFilterType.SELECT_WITH_EXPRESSION:
        if (columnFilter.values && columnFilter.values.length > 0) {
          filter[column.filterName] = combineValues(filter[column.filterName], columnFilter);
        }
        break;
      case QuickFilterType.EXPRESSION:
        filter[column.filterName] = combineExpression(filter[column.filterName], columnFilter);
        break;
    }
  });

  return filter;
}

function combineValues(values: string, column: ColumnQuickConfig): string {
  if (!values || values.length === 0) {
    return column.values.join('|');
  }
  return values + '|' + column.values.join('|');
}

function combineExpression(origin: string, column: ColumnQuickConfig): string {
  if (column.expression && column.expression.length > 0) {
    const value = column.expression.replace(/ /g, '|').replace('^', '');
    if (!value.endsWith('*')) {
      return value + '*';
    } else {
      return value;
    }
  } else {
    return origin;
  }
}
