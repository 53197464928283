export class Constants {
  static readonly BAR_CHART_TYPE = 'bar';
  static readonly PIE_CHART_TYPE = 'pie';

  static readonly ZERO = 0;
  static readonly DOT = ".";

  static readonly WHITE = 'rgb(238,238,238)';
  static readonly WHITE_GRAY = 'rgb(198, 195, 201)';
  static readonly BLUE = 'rgb(58, 208, 222)';
  static readonly BLACK = 'rgb(0,0,0)';
  static readonly GREEN = 'rgb(0,128,0)';
  static readonly YELLOW = 'rgb(255, 255, 0)';
  static readonly GREEN_YELLOW = 'rgb(173, 255, 47)';
  static readonly ORANGE = 'rgb(255, 165, 0)';
  static readonly RED = 'rgb(255, 0, 0)';
  static readonly PURPLE = 'rgb(155, 74, 237)';
  static readonly DARK_RED = "rgb(139,0,0)";
  static readonly DARK_GRAY = "rgb(169,169,169)";
  static readonly DARK_MAGENTA = "rgb(139,0,139)";
  static readonly DARK_OLIVE_GREEN = "rgb(85,107,47)";
  static readonly DARK_ORANGE = "rgb(255,140,0)";

  static readonly START = 'start';
  static readonly CENTER = 'center';

  static readonly BOTTOM = 'bottom';

  static readonly APERAK = 'APERAK';
  static readonly CONTRL = 'CONTRL';
  static readonly IFTSTA = 'IFTSTA';
  static readonly INVOIC = 'INVOIC';
  static readonly MSCONS = 'MSCONS';
  static readonly ORDERS = 'ORDERS';
  static readonly PRICAT = 'PRICAT';
  static readonly REMADV = 'REMADV';
  static readonly REQDOC = 'REQDOC';
  static readonly REST = 'REST';
  static readonly SSQNOT = 'SSQNOT';
  static readonly UNKNOWN = 'UNKNOWN';
  static readonly UTILMD = 'UTILMD';

  static readonly CONFLICT_CODE = 409;
  static readonly PROFESSIONAL = "PROFESSIONAL";
  static readonly TECHNICAL = "TECHNICAL";

  static readonly B3P_BASE_MESSAGE = "B3P_BASE_MESSAGE";
  static readonly B3P_SPLIT_INFO = "B3P_SPLIT_INFO";

  static readonly B2B_ROLE_DASHBOARD = 'B2B-Dashboard';
  static readonly B2B_MESSAGE_CREATOR = "B2B-MessageCreator";
  static readonly B2B_ROLE_MESSAGE_MONITOR_READ = 'B2B-MessageMonitor-Read';
  static readonly B2B_ROLE_MESSAGE_MONITOR_WRITE = 'B2B-MessageMonitor-Write';
  static readonly B2B_ROLE_MESSAGE_MONITOR_TECHNICAL_DETAIL = 'B2B-MessageMonitor-TechnicalDetails';
  static readonly B2B_ROLE_MANUAL_MARKET = 'B2B-ManualMarketCommunication';
  static readonly B2B_ROLE_MPID_EDITOR_READ = 'B2B-MpidEditor-Read';
  static readonly B2B_ROLE_MPID_EDITOR_WRITE = 'B2B-MpidEditor-Write';
  static readonly B2B_ROLE_MPID_EDITOR_CONTACT_DATA_WRITE = 'B2B-MpidEditor-Write-ContactData';
  static readonly B2B_ROLE_ERRORS = 'B2B-Errors';
  static readonly B2B_ROLE_SYSTEM_SWITCH = 'B2B-SystemSwitch';
  static readonly B2B_ROLE_SYSTEM_SWITCH_ADMIN = 'B2B-SystemSwitchAdmin';
  static readonly B2B_ROLE_REVISION_MANAGER_READ = 'RevisionManager-Read';
  static readonly MP_CERTIFICATES_WRITE_ROLE = 'Mp-Certificate-Importer-Write';
  static readonly MP_CERTIFICATES_READ_ROLE = 'Mp-Certificate-Importer-Read';

  static readonly DEBOUNCE_TIME_CLICK_IN_MILLISECONDS = 200;
  static readonly DELAY_ROW_CLICK_IN_MILLISECONDS = 300;

  static readonly DELAY_ROW_SCROLL_IN_MILLISECONDS = 350;
  static readonly TYPE_ACK = 'ACK';
  static readonly TYPE_STATE = "STATE";

  static readonly MESSAGE_MONITOR_WEB = 'message-monitor-web';
  static readonly MAID_EDITOR_WEB = 'app-mpid-editor-web';
  static readonly DASHBOARD_WEB = 'dashboard-web';
  static readonly SEND_MANUALLY_WEB = 'send-manually-web';

  static readonly EDIFACT_EXPORTER_SERVICE = "edifact-exporter";
  static readonly ERROR_LIST_KEY = 'errorList';
  static readonly B2B_UI_PORTAL = 'B2B_UI_PORTAL';
  static readonly B2B_UI_BOTTOM_SHEET = 'bottom-sheet-container-b2b-ui';
  static readonly B2B_UI_BOTTOM_SHEET_SHRINK = 'bottom-sheet-shrink-b2b-ui';
}
