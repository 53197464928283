import {NgModule} from '@angular/core';
import {RouterModule, Routes, UrlSegment} from "@angular/router";
import {KeycloakAuthGuard, RoleGuard} from "@next-level-integration-private/nli-keycloak-auth";
import {LayoutComponent} from "./shared/components/layout/layout.component";
import {Constants} from "./core/common/constants";
import {WorklistModule} from "./pages/worklist/worklist.module";
import {AccessDeniedPageComponent} from "./pages/access-denied-page/access-denied-page.component";
import {CustomDashboardService} from "./core/services/custom-dashboard.service";


const childRoutes = [
  {
    matcher: customeDashboardUrlMatcher,
    loadChildren: () => import('./pages/custom-dashboard/custom-dashboard.module').then(m =>
      m.CustomDashboardModule),
    data: {title: "customdashboard.title", expectedRole: Constants.B2B_ROLE_DASHBOARD},
    canActivate: [RoleGuard]
  },
  {
    matcher: dashboardUrlMatcher,
    loadChildren: () => import('./pages/dashboard-web/dashboard-web.module').then(m => m.DashboardWebModule),
    data: {title: "dashboard.title", expectedRole: Constants.B2B_ROLE_DASHBOARD},
    canActivate: [RoleGuard],
  },
  {
    path: 'sendmanually',
    loadChildren: () => import('./pages/send-manually-web/send-manually-web.module').then(m => m.SendManuallyWebModule),
    data: {title: "sendManually.title", expectedRole: Constants.B2B_ROLE_MANUAL_MARKET},
    canActivate: [RoleGuard]
  },
  {
    path: Constants.MESSAGE_MONITOR_WEB,
    loadChildren: () => import('./pages/message-monitor-web/message-monitor-web.module').then(m => m.MessageMonitorWebModule),
    data: {title: "messageMonitor.title", expectedRole: Constants.B2B_ROLE_MESSAGE_MONITOR_READ},
    canActivate: [RoleGuard]
  },
  {
    path: 'marketpartneradmin',
    loadChildren: () => import('./pages/market-partner-administration-web/market-partner-administration-web.module').then(m =>
      m.MarketPartnerAdministrationWebModule),
    data: {title: "marketPartnerAdministration.title", expectedRole: Constants.B2B_ROLE_MPID_EDITOR_READ},
    canActivate: [RoleGuard]
  },
  {
    path: 'systemswitch',
    loadChildren: () => import('./pages/system-switch/system-switch.module').then(m =>
      m.SystemSwitchModule),
    data: {title: "systemSwitch.title", expectedRole: Constants.B2B_ROLE_SYSTEM_SWITCH},
    canActivate: [RoleGuard]
  }];

const routes: Routes = [
  {path: '', redirectTo: 'b2b', pathMatch: 'full'},
  {path: 'access-denied', component: AccessDeniedPageComponent},
  {path: 'b2b', component: LayoutComponent, canActivate: [KeycloakAuthGuard], children: childRoutes}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }), WorklistModule],
  providers: [CustomDashboardService],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

export function customeDashboardUrlMatcher(urlSegments: UrlSegment[]) {
  const customDashboardService: CustomDashboardService = new CustomDashboardService();
  if (urlSegments.length === 0 && customDashboardService.isCustomDashboardAvailable()) {
    return {consumed: urlSegments};
  } else if (urlSegments.length === 1 && customDashboardService.isCustomDashboardAvailable()) {
    const path = urlSegments[0].path;
    if (path.startsWith('customdashboard')) {
      return {consumed: urlSegments};
    }
  }
  return null;
}

export function dashboardUrlMatcher(urlSegments: UrlSegment[]) {
  if (urlSegments.length === 0) {
    return {consumed: urlSegments};
  } else if (urlSegments.length === 1) {
    const path = urlSegments[0].path;
    if (path.startsWith('dashboard')) {
      return {consumed: urlSegments};
    }
  }
  return null;
}
