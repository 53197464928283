import {AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Message, MessageFilter, MessageTableColumnConfig} from '../../../../core/models/message.model';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {ClearingCasesDatasource} from "../../../../pages/system-switch/clearing-cases/clearing-cases.datasource";
import {TableUtil} from "@next-level-integration/nli-core";
import {HotkeysService} from "@next-level-integration/nli-hotkeys";
import {MLocation, MLocationFilter} from "../../../../core/models/system-switch.model";
import {MeloMaloDatasource} from "../../../../pages/system-switch/melo-malo/melo-malo.datasource";
import {MatBottomSheet, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {MatSort, Sort} from "@angular/material/sort";
import {NavigationStart, Router} from "@angular/router";
import {DetailComponent} from "../../../../pages/system-switch/clearing-cases/detail/detail.component";
import {Constants} from "../../../../core/common/constants";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Subscription} from "rxjs/Rx";
import {debounceTime, tap} from "rxjs/operators";
import {fromEvent} from "rxjs/internal/observable/fromEvent";

@Component({
  selector: 'nli-system-switch-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class SystemSwitchTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  filterSubject: BehaviorSubject<MessageFilter | MLocationFilter>;

  @Input()
  paginatorSubject: BehaviorSubject<any>;

  @Input()
  sortSubject: BehaviorSubject<Sort>;

  @Input()
  detailSubject: BehaviorSubject<Message | MLocation>;

  @Input()
  dataSource: ClearingCasesDatasource | MeloMaloDatasource;

  @Input()
  selection: SelectionModel<Message | MLocation>;

  @Input()
  tableProperties: MessageTableColumnConfig[];
  @Input()
  identifier: string;

  @ViewChild('pagination') paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = new Array<string>();

  selectedDetail: Message | MLocation;
  selectedIndex: number;
  bottomSheetRef: MatBottomSheetRef = null;
  bottomSheetNavigationSubscription: Subscription = null;
  isSingleClick = false;
  executeCallAfterDelay = false;
  timer;

  constructor(private readonly hotkeys: HotkeysService,
              private readonly bottomSheet: MatBottomSheet,
              private readonly el: ElementRef,
              private readonly router: Router) {
    this.bottomSheetNavigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.bottomSheetRef != null) {
          this.bottomSheetRef.dismiss();
          this.bottomSheetNavigationSubscription.unsubscribe();
        }
      }
    });
  }

  ngOnInit() {
    this.hotkeys.activatePaginationHotkeys();
    for (const col of this.tableProperties) {
      if (col.visible) {
        this.displayedColumns.push(col.id);
      }
    }
    this.registerClickEvent();
  }

  ngAfterViewInit() {
    this.filterSubject.subscribe(filter => {
      if (filter) {
        this.paginator.pageIndex = 0;
      }
      this.closeBottomSheet();
    });

    this.paginator.page
      .pipe(
        tap(() => {
          if (this.selection) {
            this.selection.clear();
          }
        })
      )
      .subscribe(obj => {
        this.paginatorSubject.next(obj);
      });

    this.sort.sortChange.subscribe((sort: Sort) => {
      this.sortSubject.next(sort);
    });
  }

  ngOnDestroy(): void {
    this.filterSubject.unsubscribe();
  }

  masterToggle() {
    this.emitNewMessage(null);
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach(row => {
        if (!this.selection.isSelected(row)) {
          this.selection.select(row);
        }
      });
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  tableOnKeyUp(event: KeyboardEvent) {
    if (this.selection.isEmpty()) {
      TableUtil.keydownOnTable(event, this.dataSource.data, this.selectedDetail, this.selectedIndex, newIndex => {
        this.selectedIndex = newIndex;
        this.selectedDetail = this.dataSource.data[this.selectedIndex];
      });
      this.delayTimerOnContinuousClicks();
    }
  }

  delayTimerOnContinuousClicks() {
    if (this.executeCallAfterDelay === false) {
      clearTimeout(this.timer);
    }
    this.executeCallAfterDelay = false;
    this.timer = setTimeout(() => {
      this.emitNewMessage(this.dataSource.data[this.selectedIndex]);
      this.executeCallAfterDelay = true;
    }, Constants.DELAY_ROW_SCROLL_IN_MILLISECONDS);
  }

  selectionToggle(row) {
    this.emitNewMessage(null);
    this.selection.toggle(row);
  }

  private emitNewMessage(detail: Message | MLocation) {
    this.selectedDetail = detail;
    if (detail && !(detail instanceof MLocation)) {
      this.openBtmSheet(detail);
    }
  }

  private openBtmSheet(detail: Message | MLocation) {
    const panelClass = ['bottom-sheet'];
    if (this.bottomSheetRef != null) {
      const bottomSheetElement = document.querySelector('.bottom-sheet');
      if (bottomSheetElement !== null
        && bottomSheetElement.classList.contains('bottom-sheet-shrink')) {
        panelClass.push('bottom-sheet-shrink');
      }
      this.bottomSheetRef.dismiss();
    }
    this.bottomSheetRef = this.bottomSheet.open(DetailComponent, {
      data: {
        detail,
        tableKeyCallback: (event: KeyboardEvent) => this.tableOnKeyUp(event)
      },
      hasBackdrop: false,
      closeOnNavigation: true,
      autoFocus: true,
      panelClass
    });
  }

  registerClickEvent() {
    const clickEvent = fromEvent<MouseEvent>(this.el.nativeElement, 'click');
    clickEvent.pipe(debounceTime(Constants.DEBOUNCE_TIME_CLICK_IN_MILLISECONDS)).subscribe((data) => {
      if (data.detail === 1) {
        this.isSingleClick = true;
      }
    });
  }

  handleRowClick(detail: Message | MLocation): void {
    setTimeout(() => {
      if (this.isSingleClick) {
        this.tableRowClicked(detail);
      }
    }, Constants.DELAY_ROW_CLICK_IN_MILLISECONDS);
  }

  @HostListener('dblclick') onDblClick() {
    this.isSingleClick = false;
  }

  private tableRowClicked(detail: Message | MLocation) {
    if (!this.selection || this.selection.isEmpty()) {
      this.emitNewMessage(detail);
    } else {
      this.selectionToggle(detail);
    }
  }


  eventChange(event: PageEvent) {
    localStorage.setItem("pageSizeSystemSwitch", event.pageSize.toString());
  }

  getDefaultPageSize(): number {
    const pageSize = localStorage.getItem("pageSizeSystemSwitch");
    if (pageSize) {
      return Number(pageSize);
    }

    if (this.dataSource instanceof MeloMaloDatasource) {
      return MeloMaloDatasource.DEFAULT_PAGE_SIZE;
    }
    return ClearingCasesDatasource.DEFAULT_PAGE_SIZE;
  }

  @HostListener('window:keyup.ArrowRight', ['$event'])
  @HostListener('window:keyup.ArrowLeft', ['$event'])
  @HostListener('window:keyup.control.ArrowRight', ['$event'])
  @HostListener('window:keyup.control.ArrowLeft', ['$event'])
  changePage(event: KeyboardEvent) {
    this.hotkeys.changePage(this.paginator, event.ctrlKey, event.key);
  }

  @HostListener('window:keydown.ArrowUp', ['$event'])
  @HostListener('window:keydown.control.ArrowUp', ['$event'])
  @HostListener('window:keydown.ArrowDown', ['$event'])
  @HostListener('window:keydown.control.ArrowDown', ['$event'])
  handleScrollUpDown(event: KeyboardEvent) {
    this.tableOnKeyUp(event);
  }

  closeBottomSheet() {
    if (this.bottomSheetRef != null) {
      this.bottomSheetRef.dismiss();
    }
  }
}
