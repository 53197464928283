<mat-toolbar class="primary-toolbar white-text">

  <img src='assets/NLI.png' *ngIf="showImage" class="toolbar-image" alt="NLI"/>

  <ng-content></ng-content>

  <div class="main-menu account-menu">
    <nli-account-info [serverInfoProxyUrl]="serverInfoPath" [systemName]="systemName"></nli-account-info>
  </div>
  <div *ngIf="isErrorsRole" class="main-menu-btn">
    <nli-header-error></nli-header-error>
  </div>
  <div class="main-menu-btn">
    <nli-system-message-display-icon [isActiveUserMessages]="activateUserMessages"
                                     [systemMessageProxyUrl]="systemMessagesPath"></nli-system-message-display-icon>
  </div>
  <div class="header-i18n">
    <nli-i18n></nli-i18n>
  </div>
  <div class="main-menu-btn">
    <nli-account-menu [displayB2BDocumentation]="true">
    </nli-account-menu>
  </div>
  <div class="main-menu-btn">
    <nli-portal-menu></nli-portal-menu>
  </div>
</mat-toolbar>
