<div class="detail-btm-sheet">
  <mat-progress-bar mode="query" *ngIf="isLoading"></mat-progress-bar>

  <div class="system-switch-clearing-detail-header">
    <div class="card-header">
      {{selectedMessageDetail.sender}}
    </div>
    <div class="close-btn">
      <button mat-icon-button title="{{'certificates.detail.close' | translate}}" (click)="dismiss()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="message-content">
    <ng-container>
      <nli-message-monitor-professional-details [messageDetailSubject]="messageDetailSubject" [isFetchTechnicalAttributes]="true"
                                                [attributesAllowed]="attributesAllowed" class="full-width"></nli-message-monitor-professional-details>
    </ng-container>
  </div>
</div>