import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {SnackbarMessagesService} from "@next-level-integration/nli-core";
import {Observable} from "rxjs/Observable";
import {MessageService} from "../../../../core/apis/message.service";
import {PersonalizationService} from "../../../personalization/personalization.service";
import {FilterConfig} from "../../../personalization/table-config";

@Injectable()
export class FilterService {
    private static readonly PERSONALIZATION_KEY = 'messageMonitorPersonalization';

    constructor(private readonly translateService: TranslateService,
                private readonly snackbarMessagesService: SnackbarMessagesService,
                private readonly messageService: MessageService,
                private readonly personalizationService: PersonalizationService) {
        this.getFilterConfig(true).subscribe(config => {
            this.personalizationService.addToDefaultCache(FilterService.PERSONALIZATION_KEY + 'Filter', config);
        });
    }


    public getChannelDataList(): any {
        const channelList = [];
        this.messageService.getChannelList()
            .subscribe(data => {
                    data.forEach(channel => {
                        if (channel && channel.trim()) {
                            channelList.push({label: channel, value: channel});
                        }
                    });
                },
                error => this.snackbarMessagesService.handleError(error, 'messageMonitor.snackBar.failedMsg.channelsDataFilter'));
        return channelList;
    }


    public getFilterConfig(withoutPersonalization = false): Observable<FilterConfig[]> {
        let defaultFilterConfig: FilterConfig[] = FilterService.getDefaultFilterConfig();
        const lsTableConfig = this.personalizationService.getFilterPersonalization(FilterService.PERSONALIZATION_KEY);
        if (!withoutPersonalization && (lsTableConfig && lsTableConfig.length > 0)) {
            defaultFilterConfig = lsTableConfig;
        }
        return this.messageService.getTableConfig().map(table => {

            defaultFilterConfig.forEach(defaultEntry => {
                const tableColumnEntry = table.columns.find(entry => defaultEntry.id === entry.id);
                if (tableColumnEntry) {
                    if (tableColumnEntry.label) {
                        defaultEntry.label = tableColumnEntry.label;
                    }
                } else {
                  const transientFilters = ['fullText', 'dateField', 'searchArchive', 'messageId'];
                  if (transientFilters.indexOf(defaultEntry.id) < 0) {
                    defaultEntry.visible = false;
                    defaultEntry.isChangeVisibilityAllowed = false;
                  }
                }
            });
            return defaultFilterConfig;
        });
    }

    private static getDefaultFilterConfig() {
        const filterConfig: FilterConfig[] = [
            {id: "fullText", label: "messageMonitor.search", isReorderingAllowed: false, ordering: -3, visible: true},
            {
                id: "dateField",
                label: "messageMonitor.dateRangeLabel",
                isReorderingAllowed: false,
                isChangeVisibilityAllowed: false,
                ordering: -2,
                visible: true
            },
            {id: "vs", label: "messageMonitor.processingStatus", isReorderingAllowed: false, ordering: -1, visible: true},
            {id: "bs", label: "messageMonitor.acknowledgementStatus", ordering: 0, visible: true},
            {id: "cs", label: "messageMonitor.clearing", ordering: 1, visible: true},
            {id: "system", label: "messageMonitor.system", ordering: 2, visible: true},
            {id: "partner", label: "messageMonitor.partner", ordering: 3, visible: true},
            {id: "ref-no", label: "messageMonitor.referenceNumber", ordering: 4, visible: true},
            {id: "idoc-no", label: "Idoc-Nr.", ordering: 5, visible: true},
            {id: "messageId", label: "messageMonitor.messageId", ordering: 6, visible: true},
            {id: "inbound-format", label: "messageMonitor.incoming", ordering: 7, visible: true},
            {id: "outbound-format", label: "messageMonitor.outgoing", ordering: 8, visible: true},
            {id: "version", label: "messageMonitor.version", ordering: 9, visible: true},
            {id: "type", label: "messageMonitor.type", ordering: 10, visible: true},
            {id: "direction", label: "messageMonitor.direction", ordering: 11, visible: true},
            {id: "channel", label: "messageMonitor.channel", ordering: 12, visible: true},
            {id: "additional1", label: "Additional1(Configurable)", ordering: 13, visible: true},
            {id: "additional2", label: "Additional2(Configurable)", ordering: 14, visible: true},
            {id: "additional3", label: "Additional3(Configurable)", ordering: 15, visible: true},
            {id: "additional4", label: "Additional4(Configurable)", ordering: 16, visible: true},
            {id: "searchArchive", label: "messageMonitor.searchArchive", ordering: 17, visible: true},
        ];
        return filterConfig;
    }
}
