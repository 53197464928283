import {Component, HostListener, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Message} from "../../../../core/models/message.model";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Constants} from "../../../../core/common/constants";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";

@Component({
  selector: 'nli-system-switch-clearing-cases-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {
  messageDetailSubject: BehaviorSubject<Message> = new BehaviorSubject<Message>(null);
  attributesAllowed = [Constants.B3P_BASE_MESSAGE, Constants.B3P_SPLIT_INFO];
  selectedMessageDetail: Message;
  isLoading: true;
  tableKeyCallback: (event: KeyboardEvent) => void;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              private readonly sheet: MatBottomSheetRef) {
    this.messageDetailSubject.next(data.detail);
    this.selectedMessageDetail = data.detail;
    this.tableKeyCallback = data.tableKeyCallback;
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.messageDetailSubject.unsubscribe();
  }

  dismiss() {
    this.sheet.dismiss();
  }
}
