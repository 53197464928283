import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MaterialModule} from './material.module';
import {APP_INITIALIZER, NgModule} from "@angular/core";
import {AppComponent} from "./app.component";
import {registerLocaleData} from '@angular/common';

import {HttpClient, HttpClientModule} from '@angular/common/http';

import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import {CoreModule} from "./core/core.module";
import {SharedModule} from "./shared/shared.module";
import {AppRoutingModule} from "./app-routing.module";
import {BaseAuthConfig, KeycloakAuthModule} from "@next-level-integration-private/nli-keycloak-auth";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {MultiHttpTranslateLoader} from "./multi-http-translate-loader";
import {environment} from "../environments/environment";
import {ApiService, SystemConfigService} from "@next-level-integration/nli-core";
import {AccessDeniedPageModule} from "./pages/access-denied-page/access-denied-page.module";

registerLocaleData(localeDe, 'de-DE');
registerLocaleData(localeEn, 'en');

export const authConfig: BaseAuthConfig = {
  clientId: 'b2b-ui',
  loginRedirectAppendix: 'b2b/dashboard',
  logoutRedirectAppendix: 'b2b',
  redirectToUrlUserTyped: true
};

export function initApp(api: ApiService) {
  return () => api.init(environment);
}

export function initConfig(systemConfigService: SystemConfigService) {
  return () => systemConfigService.initConfig(environment);
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    SharedModule,
    MaterialModule,
    HttpClientModule,
    AppRoutingModule,
    AccessDeniedPageModule,
    KeycloakAuthModule.forRoot(authConfig)
  ],
  declarations: [
    AppComponent
  ],
  providers: [ApiService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [
        ApiService
      ]
    }, SystemConfigService, {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      multi: true,
      deps: [
        SystemConfigService
      ]
    }],
  bootstrap: [AppComponent],
  exports: [TranslateModule]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiHttpTranslateLoader(http, [
    {prefix: "./lib/system-messages/assets/i18n/", suffix: ".json"},
    {prefix: "./assets/i18n/", suffix: ".json"},
    {prefix: "./lib/list-errors/assets/i18n/", suffix: ".json"},
    {prefix: "./lib/nli-core/assets/i18n/", suffix: ".json"},
    {prefix: "./lib/nli-hotkeys/assets/i18n/", suffix: ".json"},
    {prefix: "./lib/nli-account-info/assets/i18n/", suffix: ".json"},
    {prefix: "./lib/nli-access-denied/assets/i18n/", suffix: ".json"},
    {prefix: "./lib/nli-portal/assets/i18n/", suffix: '.json'}
  ]);
}
