import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AccessDeniedPageComponent} from './access-denied-page.component';
import {SharedModule} from '../../shared/shared.module';
import {LibrariesModule} from '../../libraries.module';
import {NliAccessDeniedModule} from '@next-level-integration/nli-access-denied';
import {I18nModule} from '@next-level-integration-private/nli-i18n';

@NgModule({
  imports: [
    CommonModule,
    LibrariesModule,
    NliAccessDeniedModule,
    I18nModule,
    SharedModule,
  ],
  declarations: [AccessDeniedPageComponent],
  exports: [],
})
export class AccessDeniedPageModule {}
