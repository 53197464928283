export class TableConfig {
    columns: ColumnConfig[];
    filters?: FilterConfig[];
}

export class BaseConfig {
    id: string;
    label: string;
    visible = true;
    isReorderingAllowed? = true;
    isChangeVisibilityAllowed? = true;
}

export class ColumnConfig extends BaseConfig {
    width: number;
}

export class FilterConfig extends BaseConfig {
    ordering: number;
}



