import {AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostListener, Input, Renderer2, TemplateRef} from "@angular/core";
import {fromEvent} from "rxjs";
import {PersonalizationService} from "../personalization.service";
import {ColumnConfig} from "../table-config";

@Directive({
    selector: '[resizeTableColumns]'
})
export class ResizeTableDirective implements AfterViewInit {

    @Input('index') index: number;
    @Input('columnConfig') columnConfig: ColumnConfig[];
    @Input('personalizationKey') personalizationKey: string;
    element: HTMLElement;

    constructor(el: ElementRef, private renderer: Renderer2, private personalizationService: PersonalizationService, public cdRef:ChangeDetectorRef) {
        this.element = el.nativeElement as HTMLElement;
        const dragStart$ = fromEvent<MouseEvent>(this.element, "mousedown");
        dragStart$.subscribe((event: MouseEvent) => this.onResizeColumn(event));
    }

    pressed = false;
    currentResizeIndex: number;
    startX: number;
    startWidth: number;
    isResizingRight: boolean;
    resizableMousemove: () => void;
    resizableMouseup: () => void;

    setTableResize(tableWidth: number) {
        let totWidth = 0;
        if (this.columnConfig) {
            this.personalizationService.getVisibleProperties(this.columnConfig).forEach((column) => {
                totWidth += column.width;
            });
            const scale = (tableWidth - 5) / totWidth;
            this.personalizationService.getVisibleProperties(this.columnConfig).forEach((column) => {
                column.width *= scale;
            });
        }
    }

    onResizeColumn(event) {
        this.checkResizing(event, this.index);
        this.currentResizeIndex = this.index;
        this.pressed = true;
        this.startX = event.pageX;
        this.startWidth = event.target.clientWidth;
        event.preventDefault();
        this.mouseMove(this.index);
    }

    private checkResizing(event, index) {
        const visibleIndex = this.personalizationService.getVisibleIndex(this.columnConfig, index);
        const cellData = this.getCellData(visibleIndex);
        const length = this.personalizationService.getVisibleLength(this.columnConfig);
        this.isResizingRight = (visibleIndex === 0) || (Math.abs(event.pageX - cellData.right) < cellData.width / 2 && visibleIndex !== length - 1);
    }

    private getCellData(index: number) {
        return this.element.getBoundingClientRect();
    }

    mouseMove(index: number) {
        this.resizableMousemove = this.renderer.listen('document', 'mousemove', (event) => {
            if (this.pressed && event.buttons) {
                const dx = (this.isResizingRight) ? (event.pageX - this.startX) : (-event.pageX + this.startX);
                const width = this.startWidth + dx;
                if (this.currentResizeIndex === index && width > 20) {
                    this.setColumnWidthChanges(index, width);
                }
            }
        });
        this.resizableMouseup = this.renderer.listen('document', 'mouseup', (event) => {
            if (this.pressed) {
                this.pressed = false;
                this.currentResizeIndex = -1;
                this.resizableMousemove();
                this.resizableMouseup();
            }
        });
    }

    setColumnWidthChanges(index: number, width: number) {
        const orgWidth = this.columnConfig[index].width;
        const dx = width - orgWidth;
        if (dx !== 0) {
            const j = this.getResizeNeighbor(index);
            const newWidth = this.columnConfig[j].width - dx;
            if (newWidth > 20) {
                this.columnConfig[index].width = width;
                this.columnConfig[j].width = newWidth;
                this.personalizationService.saveColumnPersonalization(this.personalizationKey, this.columnConfig);
            }
        }
    }

    private getResizeNeighbor(index: number): number {
        if (this.isResizingRight) {
            return this.personalizationService.getRightVisibleNeighborIndex(this.columnConfig, index);
        }
        return this.personalizationService.getLeftVisibleNeighborIndex(this.columnConfig, index);
    }

    ngAfterViewInit(): void {
        if(this.element && this.element.offsetParent) {
            this.setTableResize(this.element.offsetParent.clientWidth);
        }
        this.cdRef.detectChanges();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if(this.element && this.element.offsetParent) {
            this.setTableResize(this.element.offsetParent.clientWidth);
        }
    }
}
