import {Injectable} from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "../../../environments/environment";

@Injectable()
export class DashboardService {

  private readonly getStatisticsUrl = environment.statisticsPath;
  private readonly getB2bNewsUrl = environment.b2bNewsPath;

  constructor(private readonly http: HttpClient) {
  }

  public getLast5Days() {
    return this.http.get(this.getStatisticsUrl + "/last5days");
  }

  public getMenuItems() {
    return this.http.get(`${this.getB2bNewsUrl}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'text/plain'),
      responseType: 'text'
    });
  }
}
