import {NgModule, Optional, SkipSelf} from '@angular/core';
import {DashboardService} from "./apis/dashboard.service";
import {MarketPartnerService} from "./apis/market-partner.service";
import {MessageService} from "./apis/message.service";
import {SendManuallyService} from "./apis/send-manually.service";
import {LoginRedirectService} from "./services/login-redirect.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {SharedModule} from "../shared/shared.module";
import {MaterialIconService} from "./services/material-icon.service";
import {HotkeysService} from "@next-level-integration/nli-hotkeys";
import {ErrorStoreService} from "@next-level-integration/list-errors";
import {DatePipe} from "@angular/common";
import {
  AddAuthInterceptor,
  CopyService,
  DownloadFileService,
  ErrorInterceptor,
  SearchAndFormatService,
  SnackbarMessagesService
} from "@next-level-integration/nli-core";
import {ParamsEncoderIntercenptor} from "./common/params-encoder.interceptor";
import {CustomDashboardService} from "./services/custom-dashboard.service";
import {EdifactExporterAuthInterceptor} from "./common/edifact-exporter-auth-interceptor.service";

@NgModule({
  imports: [SharedModule],
  exports: [],
  providers: [
    DashboardService,
    MarketPartnerService,
    MessageService,
    SendManuallyService,
    LoginRedirectService, SearchAndFormatService,
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AddAuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: EdifactExporterAuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ParamsEncoderIntercenptor, multi: true},
    MaterialIconService,
    HotkeysService,
    CopyService, SnackbarMessagesService, DownloadFileService,
    ErrorStoreService, DatePipe, CustomDashboardService]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. You can only import the module in app.module.ts'
      );
    }
  }
}
