<div *ngIf="this.isWorklist" class="worklist-mat-select">
  <button id="backToMessageMonitorBtn" mat-icon-button (click)="closeWorklist()">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <input #workList (click)="showAllWorkListOptions()" class="worklist-select"
         matInput
         type="text"
         [formControl]="worklistControl"
         [matAutocomplete]="worklistAuto">
  <mat-autocomplete #worklistAuto="matAutocomplete" (optionSelected)="selectedWorklist($event.option.value)"
                    [displayWith]="displayFn">
    <mat-option *ngFor="let workList of worklistSelection | async" [value]="workList" class="margin-bottom-10">
      <div [ngClass]="{'highlighted-selected' : isSelectedWorkListClass(workList)}">{{workList.title}}</div>
    </mat-option>
  </mat-autocomplete>
</div>

<div *ngIf="this.showWorklistBtn" class="worklist-btn-position">
  <button class="worklist-btn" id="showWorklistBtn" mat-stroked-button
          (click)="showWorklist()">{{'messageMonitor.worklist.worklistBtn' | translate}}</button>
</div>
