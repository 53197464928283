import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Email} from "../../../core/models/email.model";


@Component({
  selector: 'app-email-list',
  templateUrl: './email-list.component.html',
  styleUrls: ['./email-list.component.scss']
})
export class EmailListComponent implements OnInit {

  emails: Array<Email> = null;

  constructor(public snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<EmailListComponent>) {
    this.emails = data['model'];
  }

  ngOnInit() {
  }
}

