import {NgModule} from "@angular/core";
import {ResizeTableDirective} from "./resize-table-directive/resize-table.directive";
import {PersonalizationDialogComponent} from "./personalization-dialog/personalization-dialog.component";
import {PersonalizationUploadDialogComponent} from "./personalization-upload-dialog/personalization-upload-dialog.component";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from "@angular/material/icon";
import {PersonalizationMenuComponent} from "./personalization-menu/personalization-menu.component";
import {MatDialogModule} from "@angular/material/dialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCommonModule} from "@angular/material/core";
import {MatInputModule} from "@angular/material/input";
import {PersonalizationService} from "./personalization.service";
import {DragDropModule} from "@angular/cdk/drag-drop";


@NgModule({
  declarations: [ResizeTableDirective, PersonalizationDialogComponent, PersonalizationUploadDialogComponent, PersonalizationMenuComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCommonModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    MatMenuModule,
    MatDialogModule,
    MatSlideToggleModule,
    DragDropModule,
    MatTooltipModule
  ],
  exports: [ResizeTableDirective, PersonalizationMenuComponent],
  entryComponents: [PersonalizationDialogComponent, PersonalizationUploadDialogComponent],
  providers: [PersonalizationService]

})
export class PersonalizationModule {
}
