import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {Injectable} from "@angular/core";

@Injectable()
export class MaterialIconService {

  constructor(private readonly matIconRegistry: MatIconRegistry, private readonly sanitizer: DomSanitizer) {
  }

  public registerMessageMonitorIcons() {
    this.matIconRegistry.addSvgIcon(
      "table-edit",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/table-edit.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "checkbox-multiple-blank-outline",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/checkbox-multiple-blank-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "checkbox-multiple-marked-outline",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/checkbox-multiple-marked-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "alpha-r-circle",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/alpha-r-circle.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "alpha-a-circle",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/alpha-a-circle.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "alpha-c-circle",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/alpha-c-circle.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "alpha-m-circle",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/alpha-m-circle.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "alpha-v-circle",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/alpha-v-circle.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "alert-decagram",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/alert-decagram.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "numeric-1-box",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/numeric-1-box.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "numeric-2-box",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/numeric-2-box.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "numeric-3-box",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/numeric-3-box.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "numeric-4-box",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/numeric-4-box.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "circle-hourglass",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/circle-hourglass.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "error-a",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/error-a.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "error-am",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/error-am.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "error-av",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/error-av.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "error-c",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/error-c.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "error-r",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/error-r.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "folder-error",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/folder-error.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "man-end",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/man-end.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "remove-from-list",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/remove-from-list.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "FSS-ERR",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/FSS-ERR.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "FSS-WARN",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/FSS-WARN.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "change-status",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/change_circle_black.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "sort_up",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/sort_up.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "sort_down",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/sort_down.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "north",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/north.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "south",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/south.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "filter_active",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/filter_alt_active.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "filter",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/filter_alt_outlined.svg")
    );
    this.registerDeleteOutlineIcon();
  }

  public registerMarketPartnerIcons() {
    this.matIconRegistry.addSvgIcon(
      "check-circle",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/market-partner/check-circle.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "pencil-circle-outline",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/market-partner/pencil-circle-outline.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "sync-cert",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/market-partner/sync-cert.svg")
    );
  }

  public registerDeleteOutlineIcon() {
    this.matIconRegistry.addSvgIcon(
      "delete-outline",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/message-monitor/delete-outline.svg")
    );
  }

  public registerHeaderIcons() {
    this.matIconRegistry.addSvgIcon(
      "alert-octagon",
      this.sanitizer.bypassSecurityTrustResourceUrl("./assets/icons/header/alert-octagon.svg")
    );
  }
}
