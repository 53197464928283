import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";

@Injectable()
export class CustomDashboardService {
  customizeDashboardUrl = environment.customDashboardUrl;
  constructor() {
  }

  isCustomDashboardAvailable(): boolean {
    if(this.customizeDashboardUrl){
      return true;
    }
    return false;
  }
}
