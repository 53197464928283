import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, map} from "rxjs/operators";
import {ErrorStoreService} from "@next-level-integration/list-errors";
import {SystemMessagesService} from "@next-level-integration/system-messages";
import {Constants} from "../../../core/common/constants";
import {NliKeycloakAuthService} from "@next-level-integration-private/nli-keycloak-auth";
import {WorklistService} from "../../../pages/worklist/worklist/worklist.service";
import {environment} from "../../../../environments/environment";
import {MatSidenav} from "@angular/material/sidenav";
import {CustomDashboardService} from "../../../core/services/custom-dashboard.service";

@Component({
  selector: 'nli-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  messageMonitorWeb = Constants.MESSAGE_MONITOR_WEB;
  isDashboardRole = false;
  isManualMarketRole = false;
  isMpidRoleRead = false;
  isMpidRoleWrite = false;
  isMessageMonitorRoleRead = false;
  isMessageMonitorRoleWrite = false;
  isSystemSwitchRole = false;
  title = 'layout.title';
  _isExpanded = false;
  isWorklist = false;
  systemMessagesPath = environment.systemMessagesPath;
  activateUserMessages = environment.activateUserMessages;
  isCustomDashboard = false;

  @ViewChild('endSideNav') endSideNav: MatSidenav;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
              private readonly nliKeycloakAuthService: NliKeycloakAuthService,
              private errorStoreService: ErrorStoreService, private systemMessagesService: SystemMessagesService,
              private readonly worklistService: WorklistService,
              private readonly customDashboardService: CustomDashboardService) {
    this.isDashboardRole = this.nliKeycloakAuthService.hasRole(Constants.B2B_ROLE_DASHBOARD);
    this.isManualMarketRole = this.nliKeycloakAuthService.hasRole(Constants.B2B_ROLE_MANUAL_MARKET);
    this.isMessageMonitorRoleRead = this.nliKeycloakAuthService.hasRole(Constants.B2B_ROLE_MESSAGE_MONITOR_READ);
    this.isMessageMonitorRoleWrite = this.nliKeycloakAuthService.hasRole(Constants.B2B_ROLE_MESSAGE_MONITOR_WRITE);
    this.isMpidRoleRead = this.nliKeycloakAuthService.hasRole(Constants.B2B_ROLE_MPID_EDITOR_READ);
    this.isMpidRoleWrite = this.nliKeycloakAuthService.hasRole(Constants.B2B_ROLE_MPID_EDITOR_WRITE);
    this.isSystemSwitchRole = this.nliKeycloakAuthService.hasRole(Constants.B2B_ROLE_SYSTEM_SWITCH);
    this.isCustomDashboard = this.customDashboardService.isCustomDashboardAvailable();
    this.router
      .events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          if (child && child.snapshot && child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return this.title;
        }))
      .subscribe((titleLangKey: string) => {
        this.title = titleLangKey;
      });
  }

  ngOnInit() {
    this.systemMessagesService.sidenavChange.subscribe(() => {
      this.endSideNav.open();
      if (this.systemMessagesService.isOpen && this.errorStoreService.isOpen) {
        this.errorStoreService.toggleErrorsListSideBar();
      }
    });
    this.errorStoreService.sidenavChange.subscribe(() => {
      this.endSideNav.open();
      if (this.systemMessagesService.isOpen && this.errorStoreService.isOpen) {
        this.systemMessagesService.toggleSystemMessageSideBar();
      }
    });
    this.worklistService.getShowWorklistChange().subscribe(isOpen => {
      this.isWorklist = isOpen;
    });
  }

  get isExpanded() {
    return this._isExpanded;
  }

  set isExpanded(value: boolean) {
    this._isExpanded = value;
    this.addClassToBottomSheetList(value, '.bottom-sheet', 'bottom-sheet-shrink');
    this.addClassToBottomSheetList(value, '.' + Constants.B2B_UI_BOTTOM_SHEET, Constants.B2B_UI_BOTTOM_SHEET_SHRINK);
  }

  addClassToBottomSheetList(value: boolean, elementClass: string, className: string) {
    const bottomSheet = document.querySelector(elementClass);
    if (bottomSheet) {
      if (value) {
        bottomSheet.classList.add(className);
      } else {
        bottomSheet.classList.remove(className);
      }
    }
  }

  ngOnDestroy() {
    this.worklistService.getShowWorklistChange().unsubscribe();
  }


  closeEndSideNav() {
    this.endSideNav.close();
    if (this.systemMessagesService.isOpen) {
      this.systemMessagesService.toggleSystemMessageSideBar();
    }
    if (this.errorStoreService.isOpen) {
      this.errorStoreService.toggleErrorsListSideBar();
    }
  }

}
