import {Injectable} from '@angular/core';
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import "rxjs/add/observable/throw";

import {HttpClient} from '@angular/common/http';
import {RcvModel, SendModel} from '../models/send-manually.model';
import {environment} from "../../../environments/environment";


@Injectable()
export class SendManuallyService {
  private readonly manualforwardsProxyUrl = environment.manualforwardsPath;

  constructor(private readonly http: HttpClient) {
  }

  public saveSend(fileBase64: string, sendData: SendModel) {
    var jsonObj = {
      edi: fileBase64,
      fileName: sendData.fileName,
      email: sendData.email,
      mpChecked: sendData.mpChecked,
      newNumber: sendData.newNumber
    };
    return this.http.post(`${this.manualforwardsProxyUrl}/messages/outbound`, jsonObj);
  }

  public saveRcv(fileBase64: string, rcvData: RcvModel) {
    var jsonObj = {
      edi: fileBase64,
      fileName: rcvData.fileName,
      email: rcvData.email,
    };
    return this.http.post(`${this.manualforwardsProxyUrl}/messages/inbound`, jsonObj);
  }

}
