import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {Message, MessageFilter} from "../../../core/models/message.model";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {Observable} from "rxjs/Observable";
import {finalize} from "rxjs/operators";
import {SystemSwitchService} from "../../../core/apis/system-switch.service";
import {SnackbarMessagesService} from "@next-level-integration/nli-core";
import { Sort } from "@angular/material/sort";

export class ClearingCasesDatasource implements DataSource<Message> {
  private readonly messageSubject = new BehaviorSubject<Message[]>([]);
  private readonly loadingSubject = new BehaviorSubject<boolean>(false);
  public totalRows = 0;
  public static DEFAULT_PAGE_SIZE = 5;
  public loading$ = this.loadingSubject.asObservable();

  constructor(private readonly systemSwitchService: SystemSwitchService,
              private readonly snackbarMessagesService: SnackbarMessagesService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<Message[]> {
    return this.messageSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.messageSubject.complete();
    this.loadingSubject.complete();
  }

  loadMessages(messageFilter: MessageFilter, pageIndex = 0, pageSize = ClearingCasesDatasource.DEFAULT_PAGE_SIZE, sort: Sort): void {
    if (!messageFilter || !messageFilter.dateFrom || !messageFilter.dateTo) {
      return;
    }

    this.loadingSubject.next(true);
    this.systemSwitchService.getMessages(messageFilter, pageSize, pageIndex, `${sort.active},${sort.direction}`)
      .pipe(
        finalize(() => this.loadingSubject.next(false)))
      .subscribe(message => {
          let rows = [];
          if (message) {
            this.totalRows = message.totalElements;
            rows = message.content;
            if (!message.empty) {
              rows = [];
              message.content.forEach(element => rows.push(element));
            }
          } else {
            this.totalRows = 0;
          }
          if (this.totalRows === 0) {
            this.snackbarMessagesService.showSnackbar("systemSwitch.snackBar.failedMsg.noMessageFoundForFilter");
          }
          this.messageSubject.next(rows);
        },
        error => {
          this.totalRows = 0;
          this.messageSubject.next([]);
          this.snackbarMessagesService.handleError(error, "systemSwitch.snackBar.failedMsg.noMessageFoundForFilter");
        }
      );
  }

  get data(): Message[] {
    return this.messageSubject.value;
  }
}
