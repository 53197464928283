import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'nli-filter-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {

    @Input() id = "filter-input-text";

    @Input() value: string;

    @Input() label: string;

    @Input() labelSize = 56;

    @Output() onSearch: EventEmitter<string> = new EventEmitter();

    @Output() onClear: EventEmitter<string> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    public enterTyped() {
        this.onSearch.emit(this.value);
    }

    isEmpty() {
        return !this.value || this.value.length === 0;
    }

    clearClicked() {
        this.value = '';
        this.onClear.emit('');
    }

}
