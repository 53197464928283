import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {NliKeycloakAuthService} from '@next-level-integration-private/nli-keycloak-auth';
import {Observable} from 'rxjs/Observable';
import {Constants} from "./constants";

@Injectable()
export class EdifactExporterAuthInterceptor implements HttpInterceptor {
  constructor(private readonly authService: NliKeycloakAuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf(Constants.EDIFACT_EXPORTER_SERVICE) < 0) {
      return next.handle(request);
    }
    const authReq = request.clone({
      headers: this.authService.addOAuthAuthorizationBearerHeader(request.headers)
    });
    return next.handle(authReq);
  }
}
