import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {DateFieldOptions, DateRange} from '@next-level-integration/nli-datefield-lib';
import {TranslateService} from "@ngx-translate/core";
import {FormControl} from '@angular/forms';
import {MessageFilter} from '../../../../core/models/message.model';
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {DateFieldUtil, SelectItem} from "@next-level-integration/nli-core";
import {MLocationFilter} from "../../../../core/models/system-switch.model";
import {SystemSwitchUtils} from "../../../../pages/system-switch/system-switch-utils";
import {SearchDataConstants} from "../../../../core/common/search-data-constants";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatAutocomplete, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatChipInputEvent} from "@angular/material/chips";
import {Constants} from "../../../../core/common/constants";
import {NliKeycloakAuthService} from "@next-level-integration-private/nli-keycloak-auth";
import {MaterialIconService} from "../../../../core/services/material-icon.service";
import {FilterService} from "./filter.service";

@Component({
  selector: 'nli-system-switch-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class SystemSwitchFilterComponent implements OnInit, AfterViewInit {
  @Input()
  filterSubject: BehaviorSubject<MessageFilter | MLocationFilter>;

  @Input()
  isClearingCase = true;

  mLocationSearchControl: FormControl;
  clearingCaseSearchControl: FormControl;
  channelControl: FormControl;
  selectedRange: DateRange;
  dateFieldOptions: DateFieldOptions;
  fromDate: Date = null;
  toDate: Date = null;

  channelDataList: SelectItem[];
  filterFocused = false;
  isSystemSwitchAdminRole: boolean;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectedChannels: string[] = [];
  filteredChannels: SelectItem[];
  @ViewChild('channelInput') channelInput: ElementRef<HTMLInputElement>;
  @ViewChild('channelAuto') channelAutocomplete: MatAutocomplete;

  constructor(private translateService: TranslateService,
              private readonly nliKeycloakAuthService: NliKeycloakAuthService,
              private filterService: FilterService,
              private materialIconsService: MaterialIconService) {
    this.materialIconsService.registerDeleteOutlineIcon();
    this.getSystemSwitchSearchCacheData();
    this.isSystemSwitchAdminRole = this.nliKeycloakAuthService.hasRole(Constants.B2B_ROLE_SYSTEM_SWITCH_ADMIN);
  }

  ngOnInit(): void {
  }

  private getSystemSwitchSearchCacheData() {
    const clearingSearchFilterCache = sessionStorage.getItem(SearchDataConstants.SYS_SWITCH_CLEARING_SEARCH_FILTER);
    const clearingSearchFilter: MessageFilter = JSON.parse(clearingSearchFilterCache);
    this.selectedChannels = [];
    if (this.isClearingCase) {
      this.initDateField(clearingSearchFilter);
    }
    if (clearingSearchFilterCache) {
      this.clearingCaseSearchControl = new FormControl(clearingSearchFilter.searchTerm, null);
      this.getSelectedOptionFromCache(clearingSearchFilter.channel).forEach(option => {
        this.selectedChannels.push(option);
      });
      this.channelControl = new FormControl(null, null);
    } else {
      this.clearingCaseSearchControl = new FormControl(null, null);
      this.channelControl = new FormControl(null, null);
    }
    this.mLocationSearchControl = new FormControl(SystemSwitchUtils.getSystemSwitchMsgMlocationSearchData(), null);
    this.setChannelsDataList();
  }

  ngAfterViewInit(): void {
    this.filterSubject.next(this.getFilter());
  }

  private setChannelsDataList() {
    this.channelDataList = this.filterService.getChannelDataList();
    this.filteredChannels = this.channelDataList;
    this.channelControl.valueChanges.subscribe(value => this.filteredChannels = this._filterChannels(value));
  }

  private initDateField(clearingSearchFilter: MessageFilter): void {
    if (clearingSearchFilter && clearingSearchFilter.fromDateUTC && clearingSearchFilter.toDateUTC) {
      this.fromDate = new Date(clearingSearchFilter.fromDateUTC);
      this.toDate = new Date(clearingSearchFilter.toDateUTC);
      this.setDateFieldOptions(clearingSearchFilter.fromDateUTC, clearingSearchFilter.toDateUTC);
    } else {
      this.setDateToTodayDate();
    }
  }

  private setDateToTodayDate() {
    this.fromDate = DateFieldUtil.getFirstTimeOfToday();
    this.toDate = DateFieldUtil.getLastTimeOfToday();
    this.setDateFieldOptions(undefined, undefined);
  }

  private getFilter(): MessageFilter | MLocationFilter {
    if (this.filterSubject.value instanceof MessageFilter) {
      return this.getMessageFilter();
    } else if (this.filterSubject.value instanceof MLocationFilter) {
      return this.getMLocationFilter();
    }
    return null;
  }

  private getMLocationFilter(): MLocationFilter {
    const searchValue = this.mLocationSearchControl.value ? this.mLocationSearchControl.value : '';
    sessionStorage.setItem(SearchDataConstants.SYS_SWITCH_METER_SEARCH, searchValue);
    return new MLocationFilter(searchValue);
  }

  private getMessageFilter(): MessageFilter {
    const messageFilter: MessageFilter = new MessageFilter();
    messageFilter.searchTerm = this.clearingCaseSearchControl.value;

    const from = new Date(this.fromDate);
    const to = new Date(this.toDate);

    if (this.isClearingCase) {
      messageFilter.dateFrom = from.toISOString();
      messageFilter.dateTo = to.toISOString();
      messageFilter.fromDateUTC = String(this.fromDate);
      messageFilter.toDateUTC = String(this.toDate);
    }
    messageFilter.channel = this.selectedChannels.length > 0 ? this.selectedChannels.join('|') : null;
    sessionStorage.setItem(SearchDataConstants.SYS_SWITCH_CLEARING_SEARCH_FILTER, JSON.stringify(messageFilter));
    return messageFilter;
  }

  onSearch() {
    this.filterSubject.next(this.getFilter());
  }

  onDateChange(event): void {
    this.fromDate = event.start;
    this.toDate = event.end;
  }

  private setDateFieldOptions(searchFromDate: string, searchToDate: string) {
    this.translateService.get("dateFieldOptionsLocale").subscribe(selectedLocale => {
      const selectableRange = new DateRange(null, null);
      if (searchFromDate && searchToDate) {
        this.selectedRange = new DateRange(DateFieldUtil.getFirstTimeOfDate(new Date(searchFromDate)),
          DateFieldUtil.getLastTimeOfDate(new Date(searchToDate)));
        this.selectedRange.timeSpan = DateRange.INDIVIDUALLY;
      } else {
        this.selectedRange = selectableRange;
        this.selectedRange.timeSpan = DateRange.TODAY;
      }
      this.dateFieldOptions = new DateFieldOptions().setOptions(true, new Date(), selectableRange,
        this.selectedRange, selectedLocale, null, false, false);
    });
  }

  @HostListener('window:keydown.ArrowUp', ['$event'])
  @HostListener('window:keydown.control.ArrowUp', ['$event'])
  @HostListener('window:keydown.ArrowDown', ['$event'])
  @HostListener('window:keydown.control.ArrowDown', ['$event'])
  @HostListener('keyup.ArrowRight', ['$event'])
  @HostListener('keyup.ArrowLeft', ['$event'])
  @HostListener('keyup.control.ArrowRight', ['$event'])
  @HostListener('keyup.control.ArrowLeft', ['$event'])
  handleRightLeft(event: KeyboardEvent) {
    if (this.filterFocused) {
      event.stopImmediatePropagation();
    }
  }

  resetFilterButtonClicked() {
    if (this.isClearingCase) {
      this.clearingCaseSearchControl = new FormControl('', null);
      this.selectedChannels = [];
      this.channelControl.setValue(null);
      this.setDateToTodayDate();
      sessionStorage.removeItem(SearchDataConstants.SYS_SWITCH_CLEARING_SEARCH_FILTER);
    } else {
      this.mLocationSearchControl = new FormControl('', null);
      sessionStorage.removeItem(SearchDataConstants.SYS_SWITCH_METER_SEARCH);
    }

  }

  private _filterChannels(filter: string): SelectItem[] {
    if (!filter || filter === '') {
      return this.channelDataList;
    }
    return this.channelDataList.filter(channel => (channel.value as string).toLowerCase().startsWith(filter.toLocaleLowerCase()));
  }

  addChannel(event: MatChipInputEvent): void {
    if (!this.channelAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if (value) {
        this.selectedChannels.push(value);
      }

      if (input) {
        input.value = '';
      }

      this.channelControl.setValue(null);
    }
  }

  removeChannel(channel: string): void {
    const index = this.selectedChannels.indexOf(channel);

    if (index >= 0) {
      this.selectedChannels.splice(index, 1);
    }
  }

  channelSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedChannels.push(event.option.viewValue);
    this.channelInput.nativeElement.value = '';
    this.channelControl.setValue(null);
  }

  public onBackspaceKeydown(event) {
    if (!this.channelInput.nativeElement.value && this.selectedChannels.length > 0) {
      this.removeChannel(this.selectedChannels[this.selectedChannels.length - 1]);
    }
    event.stopImmediatePropagation();
  }

  private getSelectedOptionFromCache(searchValue: string): Array<string> {
    if (searchValue) {
      const selectedOptions = searchValue.split('|');
      return selectedOptions;
    }
    return [];
  }
}
