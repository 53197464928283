<div class="professional-container" *ngIf="selectedMessage !== null && attributes.length > 0">
  <div class="flex-column list-container">
    <perfect-scrollbar class="action-table-viewport">
      <div (click)="attributeRowSelected(attr)" *ngFor="let attr of attributes"
           [style.background-color]="attr===selectedAttribute?'rgba(0,0,0,0.05)':''">
        <div class="professional-attr">
          <label>
            {{attr.date|date:'dd.MM.yyyy HH:mm:ss'}}&nbsp;{{attr.len}}&nbsp;{{'messageMonitor.details.bytes' | translate}}
          </label>
          <div>{{attr.name}}</div>
          <div>{{attr.preview}}</div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>

  <div class="professional-overview" *ngIf="selectedAttribute !== null">
    <!-- Overview Header and Action Buttons -->
    <perfect-scrollbar class="hide-scroll-x">
      <div class="header-container">
        <div class="card-header">{{'messageMonitor.details.overview' | translate}}</div>
        <div class="overview-actions" *ngIf="isMessageMonitorRoleWrite && isShowActionButtons">
          <button (click)="copyMessageLink()" mat-button
                  matTooltip="{{'tooltips.messageMonitor.copyMessageLink'| translate}}">
            <mat-icon>file_copy</mat-icon>
          </button>
          <button (click)="copyCoherentMessageLink()" mat-button
                  matTooltip="{{'tooltips.messageMonitor.copyCoherentMessageLink'| translate}}">
            <mat-icon>library_books</mat-icon>
          </button>
          <button (click)="showCoherentMessage()" mat-button
                  matTooltip="{{'tooltips.messageMonitor.showCoherentMessage'| translate}}">
            <mat-icon>open_in_new</mat-icon>
          </button>
        </div>
      </div>

      <div class="row attribute-container">
        <div class="col-md-12 mb-1">
          <label>{{'messageMonitor.details.name' | translate}}</label>
          <div class="break-word">{{selectedAttribute.name}}</div>
        </div>
        <div class="col-md-4">
          <label>{{'messageMonitor.details.date' | translate}}</label>
          <div class="break-word">{{selectedAttribute.date|date:'dd.MM.yyyy HH:mm:ss'}}</div>
        </div>
        <div class="col-md-4">
          <label>{{'messageMonitor.details.size' | translate}}</label>
          <div class="break-word">{{selectedAttribute.len}} {{'messageMonitor.details.bytes' | translate}}</div>
        </div>
        <div class="col-md-4">
          <label>{{'messageMonitor.details.attachmentSize' | translate}}</label>
          <div
            class="break-word">{{selectedAttribute.attachmentSize}} {{'messageMonitor.details.bytes' | translate}}</div>
        </div>
        <div class="col-md-12 mt-1 word-break">
          <label>{{'messageMonitor.details.messagePreview' | translate}}</label>
          <div *ngIf="!isAttributeURL" class="break-word">{{selectedAttribute.preview}}</div>
          <div><a *ngIf="isAttributeURL" href="{{selectedAttribute.preview}}" target="_blank" class="break-word">{{selectedAttribute.preview}}</a></div>
        </div>
      </div>

      <div class="show-download-button-row" *ngIf="selectedAttribute && !isShowDetails">
        <button (click)="showDetails()" color="accent"
                mat-stroked-button>{{'messageMonitor.loadAttachment' | translate}}</button>
        <button mat-stroked-button color="accent" (click)="downloadAttachmentWithoutFetchingDetail()"
                *ngIf="isMessageMonitorRoleWrite ">{{'messageMonitor.downloadFile' | translate}}</button>
      </div>

      <ng-container *ngIf="enableASMail() && isShowDetails; then details"></ng-container>
      <ng-container *ngIf="selectedAttribute && isShowDetails; then attach"></ng-container>
    </perfect-scrollbar>
  </div>
</div>

<div class="professional-container no-details" *ngIf="attributes.length == 0 && !loading">
  <div align="center">
    <mat-icon>
      info_outline
    </mat-icon>
    <br/><br/>
    <span>{{'messageMonitor.details.noDetails' | translate}}</span>
  </div>
</div>

<ng-template #details>
  <mat-divider *ngIf="as2Message"></mat-divider>
  <div *ngIf="as2Message">
    <div class="header-container">
      <div class="card-header">{{'messageMonitor.details.info' | translate}}</div>
      <div class="overview-actions">
        <button (click)="downloadAttribute()" *ngIf="isMessageMonitorRoleWrite" mat-button
                matTooltip="{{'tooltips.messageMonitor.downloadMessage'| translate}}">
          <mat-icon>get_app</mat-icon>
        </button>
      </div>
    </div>
    <div class="row attribute-container">
      <div class="col-md-12 mb-1">
        <label>{{'messageMonitor.details.subject' | translate}}</label>
        <div class="break-word">{{as2Message.subject}}</div>
      </div>
      <div class="col-md-12 mb-1">
        <label>{{'messageMonitor.details.sender' | translate}}</label>
        <div class="break-word">{{as2Message.fromAddress}}</div>
      </div>
      <div [ngClass]="isMailMessage ? 'col-md-4 mb-1' : 'col-md-12 mb-1'">
        <label>{{'messageMonitor.details.receiver' | translate}}</label>
        <div class="break-word">{{as2Message.toAddress}}</div>
      </div>
      <div *ngIf="isMailMessage">
        <div class="col-md-4 mb-1">
          <label>{{'messageMonitor.details.cc' | translate}}</label>
          <div class="break-word">{{mailMessage.recipientsCC.toString()}}</div>
        </div>
        <div class="col-md-4 mb-1">
          <label>{{'messageMonitor.details.bcc' | translate}}</label>
          <div class="break-word">{{mailMessage.recipientsBCC.toString()}}</div>
        </div>
      </div>
      <div *ngIf="isAS2Message">
        <div class="col-md-12 mb-1">
          <label>{{'messageMonitor.details.url' | translate}}</label>
          <div class="break-word">{{as2Message.recipientAddress}}</div>
        </div>
        <div class="col-md-6 mb-1">
          <label>{{'messageMonitor.details.agent' | translate}}</label>
          <div class="break-word">{{as2Message.userAgent}}</div>
        </div>
        <div class="col-md-6 mb-1">
          <label>{{'messageMonitor.details.AS2Version' | translate}}</label>
          <div class="break-word">{{as2Message.as2Version}}</div>
        </div>
      </div>
      <div class="col-md-12 mb-1">
        <label>{{'messageMonitor.details.message' | translate}}</label>
        <div class="break-word">{{as2Message.content}}</div>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #attach>
  <mat-divider *ngIf="selectedAttributeValue"></mat-divider>
  <div class="attach-content" *ngIf="selectedAttributeValue">
    <div class="flex-row-between">
      <div class="card-header">{{'messageMonitor.details.attachment' | translate}}</div>
      <div class="overview-actions">
        <mat-checkbox (change)="formatMessageForReadability()" [(ngModel)]="readable"
                      class="withmargin">{{'messageMonitor.details.formatted' | translate}}
        </mat-checkbox>

        <button (click)="copyAttachmentContent()" mat-button
                matTooltip="{{'tooltips.messageMonitor.copyAttachmentContent'| translate}}">
          <mat-icon>file_copy</mat-icon>
        </button>

        <button (click)="downloadAttachment()" *ngIf="isMessageMonitorRoleWrite && enableASMail()" mat-button
                matTooltip="{{'tooltips.messageMonitor.downloadAttachment'| translate}}">
          <mat-icon>get_app</mat-icon>
        </button>
      </div>
    </div>
    <div class="search-form">
      <nli-text (ngModelChange)="searchInputChange($event)" [(ngModel)]="search" id="search"
                (keydown.enter)="searchData()"
                inputLabel="{{'messageMonitor.details.search' | translate}}"></nli-text>
      <div class="search-btn">
        <button (click)="searchData()" color="accent" id="searchBtn"
                mat-stroked-button>{{'messageMonitor.details.btn.search'| translate}}</button>
        <span *ngIf="searchCountTotal">
          {{enterIndex}} / {{searchCountTotal}} {{'messageMonitor.details.search.hits'| translate}}
        </span>
      </div>
    </div>
    <perfect-scrollbar class="attachment-area">
      <div class="attach-attr-values">
        <div *ngFor="let val of attrValuesInfo">
          <div *ngIf="!isAttributeURL" [innerHTML]="val | formatNewLinesToLineBreaks"></div>
          <div *ngIf="isAttributeURL"><a  href="{{val}}" target="_blank" [innerHTML]="val | formatNewLinesToLineBreaks"></a></div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</ng-template>
