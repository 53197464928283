import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {WorklistService} from "./worklist.service";
import {FormControl} from "@angular/forms";
import {MessageService} from "../../../core/apis/message.service";
import {Worklist} from "../../../core/models/worklist.model";
import {ErrorStoreService} from "@next-level-integration/list-errors";
import {TranslateService} from "@ngx-translate/core";
import {ErrorModel} from "@next-level-integration/nli-core";
import {SearchDataConstants} from "../../../core/common/search-data-constants";
import {MessageFilter} from "../../../core/models/message.model";
import {Subject} from "rxjs/Rx";

@Component({
  selector: 'nli-worklist',
  templateUrl: './worklist.component.html',
  styleUrls: ['./worklist.component.scss']
})
export class WorklistComponent implements OnInit, OnDestroy {

  isWorklist = false;
  showWorklistBtn = false;
  worklistControl = new FormControl();
  worklistSelection = new Subject<Worklist[]>();
  validWorklistData: Worklist[];
  invalidWorklistData: Worklist[];
  @ViewChild('workList') workList: ElementRef<HTMLInputElement>;


  constructor(private readonly worklistService: WorklistService, private readonly messageService: MessageService,
              private readonly errorStoreService: ErrorStoreService,
              private readonly translateService: TranslateService) {
    this.worklistService.getShowWorklistBtn().subscribe(isShow => this.showWorklistBtn = isShow);
  }

  ngOnDestroy(): void {
    this.worklistService.getShowWorklistBtn().unsubscribe();
    this.worklistService.getShowWorklistChange().unsubscribe();
    this.worklistService.getValidWorklist().unsubscribe();
    this.worklistService.getInvalidWorklist().unsubscribe();
  }

  ngOnInit() {
    this.worklistService.getShowWorklistChange().subscribe(isOpen => {
      this.isWorklist = isOpen;
      const cacheWorklistFilter = sessionStorage.getItem(SearchDataConstants.WORKLIST_SEARCH_FILTER);
      if (isOpen && cacheWorklistFilter) {
        const worklistMessageFilter: MessageFilter = JSON.parse(cacheWorklistFilter);
        const worklist: Worklist = this.validWorklistData.find(workList => workList.title === worklistMessageFilter.workListTitle);
        this.worklistControl.setValue(worklist);
      }
    });
    this.worklistService.getValidWorklist().subscribe(validWorklist => {
      this.validWorklistData = validWorklist;
    });
    this.worklistService.getInvalidWorklist().subscribe(invalidWorklist => {
      this.invalidWorklistData = invalidWorklist;
    });
    this.worklistControl.valueChanges.subscribe((changes) => {
      if (changes) {
        const value = typeof changes === 'string' ? changes : changes.code;
        const result = value ? this._filter(value) : this.validWorklistData.slice();
        this.worklistSelection.next(result);
        return;
      }
      this.worklistSelection.next(this.validWorklistData);
    });
  }

  displayFn(worklist: Worklist): string {
    return worklist && worklist.title ? worklist.title : '';
  }

  private _filter(worklistFilter: any): Worklist[] {
    return this.validWorklistData.filter(worklist => {
      return worklist.title.toLowerCase().includes(worklistFilter.toLowerCase());
    });
  }

  private notifyInvalidWorklist() {
    this.warningWithInvalidWorklist(this.invalidWorklistData);
  }

  private warningWithInvalidWorklist(invalidWorklist: Worklist[]) {
    if (invalidWorklist.length) {
      invalidWorklist.forEach(worklist => {
        worklist.errors.forEach(err => {
          this.errorStoreService.addError(new ErrorModel(this.translateService.instant('messageMonitor.worklist.invalidWorklistMsg',
            {value: worklist.title}),
            this.translateService.instant('messageMonitor.worklist.errorsMsg.' + err), ""));
        });
      });
    }
  }

  closeWorklist() {
    this.worklistService.openWorklist(false);
  }

  showWorklist() {
    this.notifyInvalidWorklist();
    this.worklistService.openWorklist(true);
  }

  showAllWorkListOptions() {
    this.workList.nativeElement.blur();
    this.worklistSelection.next(this.validWorklistData);
    this.workList.nativeElement.select();
  }

  selectedWorklist(event: Worklist) {
    this.worklistService.selectWorklist(event);
  }

  isSelectedWorkListClass(workList: Worklist): boolean {
    if (this.worklistControl.value) {
      return (this.worklistControl.value.id === workList.id);
    }
    return false;
  }
}
