import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialModule} from "../../material.module";
import {LibrariesModule} from "../../libraries.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {WorklistService} from "./worklist/worklist.service";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    LibrariesModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [WorklistService]
})
export class WorklistModule { }
