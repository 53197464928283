import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {DateRange, MatCalendar} from "@angular/material/datepicker";
import moment, {Moment} from "moment";
import {DateRangePickerSelection} from "./date-range-picker-selection";
import {NgModel} from "@angular/forms";


@Component({
  selector: 'b2b-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {

  selectedDateRange?: DateRange<Moment>;
  @Output() selectionChanged = new EventEmitter<DateRangePickerSelection>();
  @ViewChild(MatCalendar) calendar: MatCalendar<Moment>;
  @ViewChild('startDate') fromDateNgModel: NgModel;
  @ViewChild('endDate') toDateNgModel: NgModel;

  datePattern = '^([0]?[1-9]|[1|2][0-9]|[3][0|1])[.]([0]?[1-9]|[1][0-2])[.]([0-9]{4})$';
  timePattern = '^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';
  private readonly dateFormat = 'DD.MM.YYYY';
  private readonly timeFormat = 'HH:mm';
  dateMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
  timeMask = [/\d/, /\d/, ':', /\d/, /\d/];

  @Input() selection: DateRangePickerSelection;

  fromMoment: Moment;
  toMoment: Moment;
  preset: string;
  fromDate: string;
  fromTime: string;
  toDate: string;
  toTime: string;

  mode: 'from' | 'to' = 'from';

  constructor() {
  }

  ngOnInit(): void {
    if (this.selection) {
      this.preset = this.selection.preset;
      this.selectedChange(this.selection.from, false);
      this.selectedChange(this.selection.to, false);
    } else {
      this.preset = 'individual';
    }
  }

  selectedChange(event: Moment, allDay: boolean) {

    if (this.mode === 'from') {
      this.selectedDateRange = new DateRange(allDay ? event.startOf('day') : event, null);
      this.fromMoment = this.selectedDateRange.start;
      this.toMoment = allDay ? event.clone().endOf('day') : event.clone();
      this.mode = 'to';
    } else if (this.mode === 'to') {
      const start = this.selectedDateRange.start;
      const end = allDay ? event.clone().endOf('day') : event.clone();
      if (end.isBefore(start)) {
        this.selectedDateRange = new DateRange(allDay ? event.startOf('day') : event, null);
        this.fromMoment = this.selectedDateRange.start;
        this.toMoment = end.endOf('day');
      } else {
        if (start.diff(end, 'days') !== 0) {
          this.selectedDateRange = new DateRange(start, end);
          this.mode = 'from';
        } else {
          this.selectedDateRange = new DateRange(start, null);
        }
        this.fromMoment = start;
        this.toMoment = end;
      }
    }

    this.fromDate = this.fromMoment.format('DD.MM.YYYY');
    this.fromTime = this.fromMoment.format('HH:mm');

    this.toDate = this.toMoment.format('DD.MM.YYYY');
    this.toTime = this.toMoment.format('HH:mm');
  }

  manualChange() {
    this.preset = 'individual';
    const from = this.getFromMoment();
    const to = this.getToMoment();

    if (!from.isValid()) {
      this.fromDateNgModel.control.setErrors({invalid: true});
    } else {
      this.fromDateNgModel.control.setErrors(null);
    }

    if (!to.isValid() || to.isBefore(from)) {
      this.toDateNgModel.control.setErrors({invalid: true});
    } else {
      this.toDateNgModel.control.setErrors(null);
    }
  }

  onBlur() {
    const from = this.getFromMoment();
    const to = this.getToMoment();
    if (from.isValid() && to.isValid()) {
      this.selectedDateRange = new DateRange<Moment>(from, to);
      this.calendar._goToDateInView(this.selectedDateRange.end, 'month');
    }
    this.fromMoment = from;
    this.toMoment = to;
  }

  private getToMoment(): Moment {
    return this.getMoment(this.toDate, this.toTime);
  }

  private getFromMoment(): Moment {
    return this.getMoment(this.fromDate, this.fromTime);
  }

  private getMoment(date: string, time: string): Moment {
    return moment((date ? date : '') + ' ' + (time ? time : ''), this.dateFormat + ' ' + this.timeFormat);
  }

  presetChange(event: any) {

    switch (event.value) {
      case 'allTime':
        this.updateView(moment().subtract(22, 'years').startOf('day'), moment().endOf('day'));
        break;
      case 'today':
        this.updateView(moment().startOf('day'), moment().endOf('day'));
        break;
      case 'yesterday':
        this.updateView(moment().subtract(1, 'days').startOf('day'),
          moment().subtract(1, 'days').endOf('day'));
        break;
      case 'todayAndYesterday':
        this.updateView(moment().subtract(1, 'days').startOf('day'), moment().endOf('day'));
        break;
      case 'last7Days':
        this.updateView(moment().subtract(6, 'days').startOf('day'), moment().endOf('day'));
        break;
      case 'last30Days':
        this.updateView(moment().subtract(29, 'days').startOf('day'), moment().endOf('day'));
        break;
      case 'last6Months':
        this.updateView(moment().subtract(6, 'months').startOf('day'), moment().endOf('day'));
        break;
      case 'lastYear':
        this.updateView(moment().subtract(1, 'years').startOf('day'), moment().endOf('day'));
        break;
      case 'individual':
        this.updateView(null, null);
        break;
    }

    this.mode = 'from';
    this.fromMoment = this.selectedDateRange.start;
    this.toMoment = this.selectedDateRange.end;

    if (this.selectedDateRange?.end) {
      this.calendar._goToDateInView(this.selectedDateRange.end, 'month');
    }

  }

  private updateView(from: moment.Moment, to: moment.Moment) {
    if (from) {
      this.fromDate = from.format(this.dateFormat);
      this.fromTime = from.format(this.timeFormat);
    } else {
      this.fromDate = undefined;
      this.fromTime = undefined;
    }

    if (to) {
      this.toDate = to.format(this.dateFormat);
      this.toTime = to.format(this.timeFormat);
    } else {
      this.toDate = undefined;
      this.toTime = undefined;
    }

    if (from || to) {
      this.selectedDateRange = new DateRange(from, to);
    } else {
      this.selectedDateRange = undefined;
    }

  }

  confirm() {
    this.selectionChanged.emit({preset: this.preset, from: this.fromMoment, to: this.toMoment});
  }

  clear() {
    this.mode = 'from';
    this.updateView(null, null);
    this.selectionChanged.emit(undefined);
  }
}
