import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {SelectItem} from "@next-level-integration/nli-core";

@Component({
  selector: 'nli-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent {

  @Input() label: string;
  @Input() showAllPlaceholder: boolean;
  @Input() dataList: SelectItem[];
  @Input() control: FormControl;

}
