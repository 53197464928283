//  The file contents for the current environment will overwrite these during build.
//  The build system defaults to the dev environment which uses `environment.ts`, but if you do
//  `ng build --env=prod` then `environment.prod.ts` will be used instead.
//  The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  activateUserMessages: false,
  backgroundColor: '#008ECC',
  customDashboardUrl: '',
  systemName: 'B2B',
  messageMonitorPath: 'api/b2b-messages',
  globalPropertiesPath: 'api/administration/global-properties',
  clearingPath: 'api/clearings',
  systemsPath: 'api/allowed-systems',
  channelsPath: 'api/channels/',
  messageFilterPath: 'api/b2b-messages/filters',
  actionsPath: 'api/actions',
  attributesPath: 'api/attributes',
  as2Path: 'api/as2messages',
  errorsPath: 'api/errors',
  workListPath: 'api/worklists',
  systemSwitchPath: 'api/switch-messages',
  statisticsPath: 'api/statistics',
  marketPartnerManagementPath: 'api/marketpartner-relations',
  serverInfoPath: 'api/server-infos',
  securityServerPath: 'api/security-server',
  systemConfigurationPath: 'api/system-configurations',
  systemMessagesPath: 'api/b2b-user-messages/system-messages',
  manualforwardsPath: 'api/manualforwards',
  mLocationRoutingPath: 'api/reporting-points',
  b2bNewsPath: 'b2bNews',
  b2bNewsDefault: 'http://b2bbp.next-level-help.org/b2b_rn__ui.html',
  revisionManagerPath: 'api/revisionmanager',
  zipDownloadServiceUrl: false,
  syncInfoPath: 'api/mp-certificate-importer/sync-info-market-partner',
  syncedMarketPartnerPath: 'api/mp-certificate-importer/synced-market-partners',
  ebixUIServerUrl: "http://ebix-ui/B2B-Ebix-UI",
  backendUrl: "/B2B-UI/api/",
  as4AddressServiceUrl: "/B2B-UI/api/as4-address/",
  b2bMessageServiceUrl: "/B2B-UI/api/as4-message/",
  b2bUrl: "http://localhost:8080/b2bbp-engine/",
  as4Style: false,
  isB2bConfigured: true,
  streamPageSize: 400,
};
