<h2 mat-dialog-title>{{title | translate}}
</h2>
<mat-dialog-content class="document-detail-font-type">
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let item of baseConfig; let i = index" cdkDrag [cdkDragDisabled]="item.isReorderingAllowed === undefined ? false : !item.isReorderingAllowed">
            <span class="personalization-label">{{item.label | translate}}</span>
            <mat-slide-toggle style="align-content: center" [checked]="item.visible" (change)="item.visible = !item.visible" [disabled]="item.isChangeVisibilityAllowed === false">{{'personalization.visible' | translate}}</mat-slide-toggle>
            <mat-icon *ngIf="item.isReorderingAllowed === undefined ? true : item.isReorderingAllowed">drag_handle</mat-icon>
                <mat-icon *ngIf="item.isReorderingAllowed === undefined ? false : !item.isReorderingAllowed"></mat-icon>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button id="btnCancel" mat-button mat-dialog-close>{{'personalization.dialogCancel' | translate}}</button>
    <button id="btnRestore" mat-stroked-button (click)="restoreDefaults()" color="accent">{{'personalization.dialogRestoreDefault' | translate}}</button>
    <button id="btnSave" (click)="save()" color="primary"
            mat-stroked-button>{{'personalization.dialogSave' | translate}}
    </button>
    <mat-icon color="primary" [matTooltip]="'personalization.infoTooltip' | translate">info</mat-icon>
</mat-dialog-actions>
