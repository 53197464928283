import {
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {PersonalizationDialogComponent} from "../personalization-dialog/personalization-dialog.component";
import {PersonalizationUploadDialogComponent} from "../personalization-upload-dialog/personalization-upload-dialog.component";
import {DomSanitizer} from "@angular/platform-browser";
import {PersonalizationService} from "../personalization.service";
import {ColumnConfig, FilterConfig, TableConfig} from "../table-config";

@Component({
    selector: 'nli-personalization-menu',
    templateUrl: './personalization-menu.component.html',
    styleUrls: ['./personalization-menu.component.css']
})
export class PersonalizationMenuComponent {

    @Input('columnConfig') columnConfig: ColumnConfig[];
    @Input('personalizationKey') personalizationKey: string;
    @Input('filterConfig') filterConfig: FilterConfig[];
    displayedColumns: string[] = [];
    @Output() columnChange = new EventEmitter<string[]>();
    @Output() filterChange = new EventEmitter<FilterConfig[]>();

    constructor(private dialog: MatDialog, private sanitizer: DomSanitizer, private personalizationService: PersonalizationService) {

    }


    setDisplayedColumns() {
        this.displayedColumns = this.personalizationService.getDisplayedColumns(this.personalizationKey, this.columnConfig);
        this.columnChange.emit(this.displayedColumns);
    }

    openEditTableDialog() {
        const dialogRef = this.dialog.open(PersonalizationDialogComponent, {
            width: 'auto',
            height: 'auto',
            data: {
                properties: this.columnConfig,
                personalizationKey: this.personalizationKey + 'Table',
                title: 'personalization.dialogTitleTable',
                dialogRef: null
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.config) {
                this.personalizationService.saveColumnPersonalization(this.personalizationKey, result);
                this.columnConfig = result.config;
                this.setDisplayedColumns();
                if(result.isRestore) {
                    window.location.reload();
                }
            }
        });
    }

    openEditFilterDialog() {
        const dialogRef = this.dialog.open(PersonalizationDialogComponent, {
            width: 'auto',
            height: 'auto',
            data: {
                properties: this.filterConfig.slice(),
                personalizationKey: this.personalizationKey + 'Filter',
                title: 'personalization.dialogTitleFilter',
                dialogRef: null
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result && result.config) {
                this.filterConfig = result.config;
                for (let i = 0; i < this.filterConfig.length; i++) {
                    this.filterConfig[i].ordering = i;
                }
                this.personalizationService.saveFilterPersonalization(this.personalizationKey, this.filterConfig);
                this.filterChange.emit(this.filterConfig);
            }
        });
    }

    openUploadPersonalizationDialog() {
        const dialogRef = this.dialog.open(PersonalizationUploadDialogComponent, {
            width: 'auto',
            height: 'auto',
            data: {personalizationKey: this.personalizationKey}
        });
        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                window.location.reload();
            }
        });
    }

    startDownload() {
        const fullConfig: TableConfig = new TableConfig();
        fullConfig.columns = this.columnConfig;
        fullConfig.filters = this.filterConfig;
        const stringJson = JSON.stringify(fullConfig);
        this.addInvisibleLinkForDownloadAndSimulateClick(stringJson);
    }

    addInvisibleLinkForDownloadAndSimulateClick(stringJson: string) {
        const element = document.createElement('a');
        element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(stringJson));
        element.setAttribute('download', this.personalizationKey + ".json");
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

}
