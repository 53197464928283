import {Injectable} from '@angular/core';
import {Observable} from "rxjs/Observable";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import "rxjs/add/observable/throw";
import {
  Action,
  AdditionalColumnsProperties, AllMessagesClearingRequest,
  AS2Attachment,
  AS2Message,
  Attachment,
  Attribute,
  AttributeValue,
  Clearing,
  ClearingCode,
  ErrorModel,
  FilterData,
  MailMessage,
  Message,
  MessageClearingRequest,
  MessageFilter,
  MessageIcon
} from "../models/message.model";
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Worklist} from "../models/worklist.model";
import {HttpClientUtil, Page, SnackbarMessagesService} from "@next-level-integration/nli-core";
import {environment} from "../../../environments/environment";
import {GlobalProperty} from "../models/global-properties.model";
import {catchError, map, publishReplay, refCount} from "rxjs/operators";
import {TableConfig} from "../../shared/personalization/table-config";
import {Constants} from "../common/constants";

@Injectable()
export class MessageService {

  private readonly messageMonitorProxyUrl = environment.messageMonitorPath;
  private readonly systemsProxyUrl = environment.systemsPath;
  private readonly channelsProxyUrl = environment.channelsPath;
  private readonly messageFilterProxyUrl = environment.messageFilterPath;
  private readonly actionsProxyUrl = environment.actionsPath;
  private readonly attributesProxyUrl = environment.attributesPath;
  private readonly errorsProxyUrl = environment.errorsPath;
  private readonly as2ProxyUrl = environment.as2Path;
  private readonly clearingProxyPath = environment.clearingPath;
  private readonly workListProxyUrl = environment.workListPath;
  private readonly getGlobalPropertiesPath = environment.globalPropertiesPath;
  private tableConfigs$: Observable<TableConfig>;

  private static readonly KEY_TEMPLATE = 'message_template';

  constructor(private readonly http: HttpClient,
              private readonly snackbarMessagesService: SnackbarMessagesService) {
  }

  public getCoherentMessageLink(messageId: string): Observable<string> {
    return this.http.get<string>(`${this.messageMonitorProxyUrl}/coherent-message-link/${messageId}`, {responseType: 'text' as 'json'});
  }

  public restartMessage(messageIds: Array<string>) {
    return this.http.post(`${this.messageMonitorProxyUrl}/restart`, messageIds);
  }

  public restartAll(filter: MessageFilter) {
    return this.http.post(`${this.messageMonitorProxyUrl}/restart-all`, filter);
  }

  public getMessagePage(messageFilter: MessageFilter,
                        pageSize: number,
                        pageNumber: number,
                        sort: string): Observable<Page<Message>> {
    let params = HttpClientUtil.getHttpParams(messageFilter);
    params = params.append("size", pageSize.toString())
      .append("page", pageNumber.toString())
      .append("sort", sort);
    return this.http.get<Page<Message>>(`${this.messageMonitorProxyUrl}/messages`, {params: params});
  }

  private downloadCSVFile(responseType: any, messageFilter: MessageFilter, sortField: string, sortDirection: string, language: string): Observable<any> {
    let params = HttpClientUtil.getHttpParams(messageFilter);
    params = params.append("format", "csv")
      .append("lang", language)
      .append("sortField", sortField)
      .append("sortDirection", sortDirection);
    return this.http.get(`${this.messageMonitorProxyUrl}/export`, {params: params, responseType: responseType});
  }

  public downloadCSVFileAsBlob(messageFilter: MessageFilter, sortField: string, sortDirection: string, language: string): Observable<any> {
    return this.downloadCSVFile('blob', messageFilter, sortField, sortDirection, language);
  }

  public downloadCSVFileAsText(messageFilter: MessageFilter, sortField: string, sortDirection: string, language: string): Observable<any> {
    return this.downloadCSVFile('text', messageFilter, sortField, sortDirection, language);
  }

  public downloadEdiZipFileFor(messageIds: string []): Observable<any> {
    const params = new HttpParams().append("messageIds", messageIds.join(','));
    return this.http.get(`${this.messageMonitorProxyUrl}/edi-export`, {params: params, responseType: 'blob'});
  }

  public downloadEdiZipFile(messageFilter: MessageFilter): Observable<any> {
    const params = HttpClientUtil.getHttpParams(messageFilter);
    if (environment.zipDownloadServiceUrl) {
      return this.http.get(`${environment.zipDownloadServiceUrl}/${Constants.EDIFACT_EXPORTER_SERVICE}`, {params: params, responseType: 'blob',})
        .pipe(catchError(err => this.http.get(`${this.messageMonitorProxyUrl}/edi-export`, {params: params, responseType: 'blob'})));
    }

    return this.http.get(`${this.messageMonitorProxyUrl}/edi-export`, {params: params, responseType: 'blob'});
  }

  public setTemplate(message: Message) {
    if (!message) {
      return;
    }
    const str = JSON.stringify(message);
    sessionStorage.setItem(MessageService.KEY_TEMPLATE, str);
  }

  getProfessionalAttributes(messageId: string, archive: boolean): Observable<Attribute[]> {
    return this.http.get<Attribute[]>(`${this.attributesProxyUrl}/professional?messageId=${messageId}&archive=${archive}`);
  }

  getTechnicalAttributes(messageId: string, actionId: string): Observable<Attribute[]> {
    if (actionId) {
      return this.http.get<Attribute[]>(`${this.attributesProxyUrl}/technical?messageId=${messageId}&actionId=${actionId}`);
    }
    return this.http.get<Attribute[]>(`${this.attributesProxyUrl}/technical?messageId=${messageId}`);
  }

  getAttributeValue(attributeId: string, messageId: string, actionId: string, archive: boolean): Observable<AttributeValue> {
    return this.http.get<AttributeValue>(`${this.attributesProxyUrl}/value?messageId=${messageId}&attributeId=${attributeId}&actionId=${actionId}&archive=${archive}`);
  }

  getMailMessage(attributeId: string, messageId: string, actionId: string, archive: boolean): Observable<MailMessage> {
    return this.http.get<MailMessage>(`${this.attributesProxyUrl}/mail-message?messageId=${messageId}&attributeId=${attributeId}&actionId=${actionId}&archive=${archive}`);
  }

  getAs2Message(messageId: string, actionId: string, attributeId: string, archive: boolean): Observable<AS2Message> {
    return this.http.get<AS2Message>(`${this.as2ProxyUrl}?messageId=${messageId}&actionId=${actionId}&attributeId=${attributeId}&archive=${archive}`);
  }

  getActions(messageId: string): Observable<Action[]> {
    return this.http.get<Action[]>(`${this.actionsProxyUrl}?messageId=${messageId}`);
  }

  getErrors(messageId: string, actionId: string): Observable<ErrorModel[]> {
    return this.http.get<ErrorModel[]>(`${this.errorsProxyUrl}?messageId=${messageId}&actionId=${actionId}`);
  }

  getChannelList(): Observable<string[]> {
    return this.http.get<string[]>(`${this.channelsProxyUrl}/id`);
  }

  getSystemList(): Observable<FilterData[]> {
    return this.http.get<FilterData[]>(`${this.systemsProxyUrl}/`);
  }

  getProcessingStatesList(): Observable<FilterData[]> {
    return this.http.get<FilterData[]>(`${this.messageFilterProxyUrl}/processing-states`);
  }

  getAcknowledgementStatesList(): Observable<FilterData[]> {
    return this.http.get<FilterData[]>(`${this.messageFilterProxyUrl}/acknowledge-states`);
  }

  getMessageTypesList(): Observable<FilterData[]> {
    return this.http.get<FilterData[]>(`${this.messageFilterProxyUrl}/message-types`);
  }

  getExtMessageIconsByType(type: string): Observable<MessageIcon[]> {
    return this.http.get<MessageIcon[]>(`${this.messageFilterProxyUrl}/ext-message-icons?type=${type}`).pipe(
      map((messageIcons: MessageIcon[]) => {
        return messageIcons;
      }),
      catchError((err: HttpErrorResponse) => {
        return this.snackbarMessagesService.handleError(err, 'messageMonitor.snackBar.failedMsg.extMessageIcons');
      }));
  }

  public getClearingCodes(): Observable<ClearingCode[]> {
    return this.http.get<ClearingCode[]>(`${this.clearingProxyPath}/codes`);
  }

  public getClearingHistory(messageId: string): Observable<Clearing[]> {
    return this.http.get<Clearing[]>(`${this.clearingProxyPath}/history?messageId=${messageId}`);
  }

  public changeSelectedMessagesStatus(request: MessageClearingRequest): Observable<any> {
    return this.http.post(`${this.clearingProxyPath}/clearItem`, request);
  }

  public changeAllMessagesStatus(request: AllMessagesClearingRequest): Observable<any> {
    return this.http.post(`${this.clearingProxyPath}/clear-all-items`, request);
  }

  public getAdditionalColumns(): Observable<AdditionalColumnsProperties[]> {
    return this.http.get<AdditionalColumnsProperties[]>(`${this.messageMonitorProxyUrl}/additional-columns`);

  }

  public downloadAttribute(messageId: string, actionId: string, attributeId: string, archive: boolean, attributeType: string): Observable<any> {
    return this.http.get(`${this.attributesProxyUrl}/export?messageId=${messageId}&actionId=${actionId}&attributeId=${attributeId}&archive=${archive}&attributeType=${attributeType}`,
      {responseType: 'blob'});
  }

  public downloadMailAttachment(messageId: string, actionId: string, attributeId: string,
                                archive: boolean, attachment: Attachment): Observable<Blob> {
    return this.http.get(`${this.attributesProxyUrl}/attachments?messageId=${messageId}&actionId=${actionId}&attributeId=${attributeId}&index=${attachment.index}&fileName=${attachment.fileName}&archive=${archive}`,
      {responseType: 'blob'});
  }

  public downloadAS2Attachment(messageId: string, actionId: string, attributeId: string,
                               archive: boolean, attachment: AS2Attachment): Observable<Blob> {
    let index = 0;
    if (attachment.index !== undefined) {
      index = attachment.index;
    }
    return this.http.get(`${this.as2ProxyUrl}/attachments?messageId=${messageId}&actionId=${actionId}&attributeId=${attributeId}&fileName=${attachment.filename}&index=${index}&archive=${archive}`,
      {responseType: 'blob'});
  }

  public getWorklistData(): Observable<Worklist[]> {
    return this.http.get<Worklist[]>(`${this.workListProxyUrl}/`);
  }

  public getGlobalProperties(): Observable<GlobalProperty[]> {
    return this.http.get<GlobalProperty[]>(`${this.getGlobalPropertiesPath}`);
  }

  public getTableConfig(): Observable<TableConfig> {
    if (!this.tableConfigs$) {
      this.tableConfigs$ = this.http.get<TableConfig>(`${this.messageMonitorProxyUrl}/table-config`).pipe(
          publishReplay(1),
          refCount()
      );
    }

    return this.tableConfigs$;
  }
}
