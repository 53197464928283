<div class="full-width">
  <!-- System Switch Table -->
  <div class="spinner-container" *ngIf="dataSource?.loading$ | async">
    <mat-progress-bar mode="query"></mat-progress-bar>
  </div>
  <div tabindex="0">
    <mat-table matSort matSortActive="started" matSortDisableClear="true" matSortDirection="desc" class="main-table" #table [dataSource]="dataSource"
               b2bCopyDoubleClick>
      <mat-header-row *cdkHeaderRowDef="['toolbar']; sticky: true;"></mat-header-row>
      <mat-header-row class="table-header-row-2" *cdkHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>

      <ng-container matColumnDef="toolbar">
        <mat-header-cell *matHeaderCellDef [attr.colspan]="100">
          <div class="system-switch-toolbar-container">
            <div class="flex-row">
              <ng-content></ng-content>
            </div>
            <div class="paginator-top">
              <div *ngIf="selection">{{ 'selectedCount' | translate }} {{selection.selected.length}}</div>
              <mat-paginator #pagination [length]="dataSource?.totalRows"
                             (page)="eventChange($event)"
                             [pageSize]="getDefaultPageSize()" [pageSizeOptions]="[5, 25, 50, 100, 200, 500]"
                             [showFirstLastButtons]="true">
              </mat-paginator>
              <nli-personalization-menu [personalizationKey]="identifier+'Personalization'" [columnConfig]="tableProperties" (columnChange)="displayedColumns = $event"></nli-personalization-menu>
            </div>
          </div>
        </mat-header-cell>
      </ng-container>

      <ng-container [matColumnDef]="column.id" *ngFor="let column of tableProperties; let i = index">
        <mat-header-cell *cdkHeaderCellDef [class]="column.columnClass" [ngStyle]="{'max-width.px': column.width}"
                         mat-sort-header="{{column.columnCell}}" [disabled]="!column.sortable" resizeTableColumns [personalizationKey]="identifier+'Personalization'" [index]="i" [columnConfig]="tableProperties">
          <div *ngIf="column.id === 'select'">
            <mat-checkbox id="selectAllCheckBox"
                          (click)="$event.stopPropagation()"
                          (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </div>
          <span *ngIf="column.id !== 'select'">{{column.label| translate}}</span>
        </mat-header-cell>
        <mat-cell *cdkCellDef="let row; let idx =index" [class]="column.columnClass" [ngStyle]="{'max-width.px': column.width}">
          <mat-checkbox *ngIf="column.id === 'select'" [id]="'selectCheckBox-'+idx" (click)="$event.stopPropagation()"
                        (change)="$event ? selectionToggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>

          <div *ngIf="column.id === 'pending'">
            <div *ngIf="row['pending']">
              <mat-icon color="accent">query_builder</mat-icon>
            </div>
          </div>

          <div *ngIf="column.id === 'started'">
            {{row['started'] | date:'dd.MM.yyyy HH:mm:ss'}}
          </div>

          <div *ngIf="column.id === 'finished'">
            {{row['finished'] | date:'dd.MM.yyyy HH:mm:ss'}}
          </div>

          <div *ngIf="column.id === 'validFromTo'">
            {{row['validFrom'] | date:'dd.MM.yyyy HH:mm:ss'}}<br />{{row['validTo'] | date:'dd.MM.yyyy HH:mm:ss'}}
          </div>

          <div *ngIf="column.id === 'balanceFromTo'">
            {{row['balanceFrom'] | date:'dd.MM.yyyy HH:mm:ss'}}<br />{{row['balanceTo'] | date:'dd.MM.yyyy HH:mm:ss'}}
          </div>

          <div *ngIf="['select', 'pending', 'started','finished', 'validFromTo', 'balanceFromTo'].indexOf(column.id) === -1">
            {{row[column.columnCell]}}
          </div>
        </mat-cell>
      </ng-container>
      <mat-row (click)="handleRowClick(row)"
               [ngClass]="{'highlight' : row === selectedDetail}"
               *cdkRowDef="let row; columns: displayedColumns"
               class="table-row"></mat-row>
    </mat-table>
  </div>
</div>
