<div class="filter-card">
  <form class="flex-row-wrap system-switch-filter">

    <mat-form-field floatLabel="always" class="filter-inputs-row" *ngIf="!isClearingCase">
      <mat-label>{{'systemSwitch.filter.searchLabel' | translate}}</mat-label>
      <input matInput [formControl]="mLocationSearchControl" name="mLocationFullTextSearchField"
             (focusin)="filterFocused = true" (focusout)="filterFocused = false"
             placeholder="{{'systemSwitch.filter.searchPlaceholder' | translate}}">
    </mat-form-field>

    <mat-form-field floatLabel="always" class="filter-inputs-row" *ngIf="isClearingCase">
      <mat-label>{{'systemSwitch.filter.searchLabel' | translate}}</mat-label>
      <input matInput [formControl]="clearingCaseSearchControl" name="clearingCaseFullTextSearchField"
             (focusin)="filterFocused = true" (focusout)="filterFocused = false"
             placeholder="{{'systemSwitch.filter.searchPlaceholder' | translate}}">
    </mat-form-field>

    <div class="date-field">
      <nli-datefield *ngIf="isClearingCase" [configs]="dateFieldOptions"
                     [selectedRange]="selectedRange"
                     (focusin)="filterFocused = true" (focusout)="filterFocused = false"
                     label="{{'systemSwitch.filter.dateRangeLabel' | translate}}"
                     (changed)="onDateChange($event)"></nli-datefield>
    </div>

    <ng-container *ngIf="isClearingCase && isSystemSwitchAdminRole">
      <!-- Channel -->
      <mat-form-field floatLabel="always">
        <mat-label>{{'messageMonitor.channel' | translate}}</mat-label>
        <mat-chip
          *ngFor="let channel of selectedChannels"
          [selectable]="true"
          [removable]="true"
          (removed)="removeChannel(channel)">
          {{channel}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <mat-chip-list #channelsChipList aria-label="channel selection">
          <input placeholder="{{'messageMonitor.searchPlaceholder' | translate}}"
                 #channelInput
                 (focusin)="filterFocused = true" (focusout)="filterFocused = false"
                 [matChipInputFor]="channelsChipList"
                 [matChipInputAddOnBlur]="true"
                 [formControl]="channelControl"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 (matChipInputTokenEnd)="addChannel($event)"
                 [matAutocomplete]="channelAuto"
                 (keydown.backspace)="onBackspaceKeydown($event)">
        </mat-chip-list>
        <mat-autocomplete #channelAuto="matAutocomplete" (optionSelected)="channelSelected($event)">
          <mat-option *ngFor="let channel of filteredChannels" [value]="channel.value"
                      matTooltip="{{channel.value}}">{{channel.label}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>

    <button mat-stroked-button color="accent" disableDoubleClickButton class="left-margin"
            (click)="onSearch()">{{'systemSwitch.filter.searchButton' | translate}}</button>
    <button mat-icon-button (click)="resetFilterButtonClicked()">
      <mat-icon matTooltip="{{'messageMonitor.filter.resetButton'| translate}}" svgIcon="delete-outline"></mat-icon>
    </button>
  </form>
</div>
