import {HttpClient} from "@angular/common/http";
import {TranslateLoader} from "@ngx-translate/core";
import merge from 'deepmerge';
import {catchError, map} from "rxjs/operators";
import {of} from "rxjs/observable/of";
import {forkJoin} from "rxjs/internal/observable/forkJoin";
import {Observable} from "rxjs/Observable";

export interface ITranslationResource {
  prefix: string;
  suffix: string;
}

export class MultiHttpTranslateLoader implements TranslateLoader {
  constructor(private readonly http: HttpClient, private readonly resources: ITranslationResource[]) {
  }

  public getTranslation(lang: string): Observable<any> {
    const requests = this.resources.map(resource => {
      const path = resource.prefix + lang + resource.suffix;
      return this.http.get(path).pipe(catchError(() => {
        console.error("Could not find translation file:", path);
        return of({});
      }));
    });
    return forkJoin(requests).pipe(map(response => merge.all(response)));
  }
}
