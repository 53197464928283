<div class="email-list-container">
    <div class="email-list-title">
        <span class="email-list-title-test" mat-dialog-title>{{'emailList.authorizedSenders' | translate}}</span>
        <button class="email-list-title-button" mat-button mat-dialog-close mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="list">
        <mat-list role="list">
            <mat-list-item role="option" *ngFor="let email of emails;">
                {{email.name}} {{email}} {{email.mpid}}
            </mat-list-item>
        </mat-list>
    </div>
</div>
