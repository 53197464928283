import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {
  Contact,
  ContactType,
  Document,
  DocumentType,
  MarketPartner,
  MarketPartnerRelation,
  UpdateEmailRequest
} from "../models/market-partner.model";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Page} from "@next-level-integration/nli-core";
import {environment} from "../../../environments/environment";

@Injectable()
export class MarketPartnerService {

  private readonly marketPartnerProxyUrl: string = environment.marketPartnerManagementPath;

  constructor(private readonly http: HttpClient) {
  }

  private getJsonHttHeaderConfig(): {} {
    return {headers: new HttpHeaders().set('Content-Type', 'application/json')};
  }

  public getDocumentType(): Observable<DocumentType[]> {
    return this.http.get<DocumentType[]>(`${this.marketPartnerProxyUrl}/document-types`);
  }

  public getMarketPartnerPage(query: string, state: string, pageSize: number, pageNumber: number, sort: string): Observable<Page<MarketPartner>> {
    let params = new HttpParams()
      .append("query", query)
      .append("state", state)
      .append("size", pageSize.toString())
      .append("page", pageNumber.toString());
    if (sort != null) {
      params = params.append("sort", sort);
    }
    return this.http.get<Page<MarketPartner>>(this.marketPartnerProxyUrl, {params});
  }

  public uploadMarketPartnerDocument(formData: FormData): Observable<any> {
    return this.http.post(`${this.marketPartnerProxyUrl}/documents`, formData, {responseType: 'text' as 'json'});
  }

  public downloadMarketPartnerDocument(documentId: string): Observable<any> {
    return this.http.get(`${this.marketPartnerProxyUrl}/documents/${documentId}`, {responseType: 'blob'});
  }

  public deleteMarketPartnerDocument(documentId: string) {
    return this.http.delete(`${this.marketPartnerProxyUrl}/documents/${documentId}`);
  }

  public getMarketPartnerDocumentList(marketPartnerId: string): Observable<Document[]> {
    return this.http.get<Document[]>(`${this.marketPartnerProxyUrl}/documents/marketpartner?marketPartnerId=${encodeURIComponent(marketPartnerId)}`,
      this.getJsonHttHeaderConfig());
  }

  public deleteRow(id: number) {
    const url = `${this.marketPartnerProxyUrl}/${id}`;
    return this.http.delete(url);
  }

  public executeMpidSyncJob() {
    return this.http.post(`${this.marketPartnerProxyUrl}/jobs/MPID_SYNC/execution`, null);
  }

  public update(model: MarketPartner) {
    this.setDefaultValidity(model);
    const marketPartnerRelation: MarketPartnerRelation = {
      id: null,
      systemMpid: model.systemMpid,
      partnerMpid: model.partnerMpid,
      partnerName: model.partnerName,
      partnerEmail: model.partnerEmail,
      validFrom: model.validFrom,
      validUntil: model.validUntil,
      serviceProviderId: model.serviceProviderId,
      signed: model.signed,
      encrypted: model.encrypted
    };
    return this.http.put(`${this.marketPartnerProxyUrl}/${model.id}`, marketPartnerRelation);
  }

  public addNew(model: MarketPartner) {
    this.setDefaultValidity(model);
    return this.http.post(`${this.marketPartnerProxyUrl}/`, model);
  }

  public updateEmail(model: MarketPartner, updateEmailRequest: UpdateEmailRequest): Observable<MarketPartnerRelation[]> {
    this.setDefaultValidity(model);
    return this.http.put<MarketPartnerRelation[]>(`${this.marketPartnerProxyUrl}/${model.id}/email`, updateEmailRequest);
  }

  private setDefaultValidity(model: MarketPartner) {
    if (model.serviceProviderId === null) {
      model.serviceProviderId = '';
    }
    if (model.validFrom === null && model.validUntil === null) {
      model.validFrom = new Date();
      model.validUntil = new Date("9999-12-31");
    }
  }

  public addContact(contact: Contact): Observable<Contact> {
    return this.http.post<Contact>(`${this.marketPartnerProxyUrl}/contacts`, contact);
  }

  public updateContact(contact: Contact): Observable<Contact> {
    return this.http.put<Contact>(`${this.marketPartnerProxyUrl}/contacts`, contact);
  }

  public deleteContactById(contactId: number) {
    return this.http.delete(`${this.marketPartnerProxyUrl}/contacts/${contactId}`);
  }

  public getContacts(marketPartnerId: string): Observable<Contact[]> {
    return this.http.get<Contact[]>(`${this.marketPartnerProxyUrl}/contacts/${encodeURIComponent(marketPartnerId)}/`);
  }

  public getContactTypes(): Observable<ContactType[]> {
    return this.http.get<ContactType[]>(`${this.marketPartnerProxyUrl}/contact-types`);
  }

  public activeInactiveContact(contact: Contact) {
    return this.http.patch(`${this.marketPartnerProxyUrl}/contacts`, contact);
  }

  public export(query: string, state: string): Observable<any> {
    return this.http.get(`${this.marketPartnerProxyUrl}/export?exportType=csv&query=${query}&state=${state}`,
      {responseType: 'blob'});
  }

  public exportContacts(query: string, state: string, type: string): Observable<any> {
    return this.http.get(`${this.marketPartnerProxyUrl}/contacts/export?exportType=csv&filter.query=${query}&filter.state=${state}&type=${type}`, {responseType: 'blob'});
  }

  public isSecurityActivated(): Observable<boolean> {
    return this.http.get<boolean>(`${this.marketPartnerProxyUrl}/security-activation`);
  }
}
