import {NgModule} from "@angular/core";
import {HeaderComponent} from "./components/header/header.component";
import {EmailListComponent} from "./components/email-list/email-list.component";
import {MultiSelectComponent} from "./components/multi-select/multi-select.component";
import {TextInputComponent} from "./components/text-input/text-input.component";
import {CommonModule} from "@angular/common";
import {LayoutComponent} from "./components/layout/layout.component";
import {MaterialModule} from "../material.module";
import {LibrariesModule} from "../libraries.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ShowStatusComponent} from "./components/show-status/show-status.component";
import {RouterModule} from "@angular/router";
import {I18nModule, LanguageData} from "@next-level-integration-private/nli-i18n";
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {TranslateModule} from "@ngx-translate/core";
import {WorklistComponent} from "../pages/worklist/worklist/worklist.component";
import {ProfessionalDetailsComponent} from './components/message-monitor/professional-details/professional-details.component';
import {HotkeysService, ShowHotkeysComponent} from '@next-level-integration/nli-hotkeys';
import {SystemSwitchTableComponent} from "./components/system-switch/table/table.component";
import {SystemSwitchFilterComponent} from "./components/system-switch/filter/filter.component";
import {PortalModule} from "@next-level-integration-private/nli-portal";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {PersonalizationModule} from "./personalization/personalization.module";
import {QuickConfigComponent} from './components/quick-config-menu/quick-config.component';
import {DisabledMenuTriggerDirective} from "./directive/disabled-menu-trigger.directive";
import {DateRangePickerComponent} from "./components/date-range-picker/date-range-picker.component";
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY} from "@angular/material/datepicker";
import {MomentDateAdapter} from "@angular/material-moment-adapter";

const languageData: LanguageData[] = [
  {
    "locale": "EN",
    "language": "English",
    "countryFlag": "assets/i18n/countryflag/us_flag.jpg"
  },
  {
    "locale": "DE",
    "language": "Deutsch",
    "countryFlag": "assets/i18n/countryflag/germany_flag.jpg"
  }
];

@NgModule({
  declarations: [HeaderComponent, EmailListComponent,
    MultiSelectComponent, TextInputComponent, LayoutComponent, ShowStatusComponent,
    FileUploadComponent, ProfessionalDetailsComponent, WorklistComponent, SystemSwitchTableComponent, SystemSwitchFilterComponent,
    QuickConfigComponent, DisabledMenuTriggerDirective, DateRangePickerComponent],
  imports: [
    CommonModule,
    MaterialModule,
    LibrariesModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forChild(),
    I18nModule.forRoot(languageData, "DE"),
    PortalModule,
    PerfectScrollbarModule,
    PersonalizationModule
  ],
  exports: [HeaderComponent, EmailListComponent, DisabledMenuTriggerDirective,
    MultiSelectComponent, TextInputComponent, LayoutComponent, FileUploadComponent,
    TranslateModule, ProfessionalDetailsComponent, SystemSwitchTableComponent, SystemSwitchFilterComponent, PersonalizationModule,
    QuickConfigComponent, DateRangePickerComponent],
  entryComponents: [EmailListComponent, ShowHotkeysComponent, QuickConfigComponent, DateRangePickerComponent],
  providers: [ShowStatusComponent, HotkeysService,
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy}]

})
export class SharedModule {
}
