<h2 mat-dialog-title>{{'personalization.dialogTitleImport' | translate}}</h2>

<mat-dialog-content class="document-detail-font-type">
    <mat-form-field floatLabel="always">
        <mat-label>{{'document.detail.label.document' | translate}}</mat-label>
        <input matInput [disabled]="true" [(ngModel)]="selectedFileName" />
        <input #choseFile type="file" accept=".json" (change)="onSelectedFile($event)"
               style="display:none;" />
        <mat-icon class="browse-hover" matSuffix (click)="choseFile.click()">attachment</mat-icon>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button id="btnCancel" mat-button mat-dialog-close>{{'personalization.dialogCancel' | translate}}</button>
    <button id="btnSave" (click)="save()"
            mat-stroked-button color="accent">{{'personalization.dialogImport' | translate}}
    </button>
</mat-dialog-actions>
