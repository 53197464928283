import {Injectable} from "@angular/core";
import {BaseConfig, ColumnConfig, FilterConfig, TableConfig} from "./table-config";

@Injectable()
export class PersonalizationService {

    private defaultCache = new Map<string, BaseConfig[]>();

    constructor() {
    }

    addToDefaultCache(personalizationKey: string, baseConfig: BaseConfig[]) {
      this.defaultCache.set(personalizationKey, this.clone(baseConfig));
    }

    getDefaultConfig(personalizationKey): BaseConfig[] {
      return this.clone(this.defaultCache.get(personalizationKey));
    }

    clone(config: BaseConfig[]): BaseConfig [] {
      const cloned = [];
      config.forEach(val => cloned.push(Object.assign({}, val)));
      return cloned;
    }

    getDisplayedColumns(personalizationKey, tableConfig) {
        if (tableConfig) {
            const displayedColumns = [];
            tableConfig.forEach((column) => {
                if (column.visible) {
                    displayedColumns.push(column.id);
                }
            });
            return displayedColumns;
        }
    }

    getVisibleProperties(baseTableConfig: ColumnConfig[]) {
        return baseTableConfig.filter(e => e.visible);
    }

    getVisibleLength(baseTableConfig: ColumnConfig[]) {
        return this.getVisibleProperties(baseTableConfig).length;
    }

    getVisibleIndex(baseTableConfig: ColumnConfig[], index) {
        if (baseTableConfig.length === 0) {
            return 0;
        }
        let visibleIndex = index;
        for (let i = 0; i < index; i++) {
            if (!baseTableConfig[i].visible) {
                --visibleIndex;
            }
        }
        return visibleIndex;
    }

    getRightVisibleNeighborIndex(baseTableConfig, index) {
        let neighborIndex = index+1;
        while (!baseTableConfig[0].visible && index < baseTableConfig.length) {
            neighborIndex++;
        }
        return neighborIndex;
    }

    getLeftVisibleNeighborIndex(baseTableConfig, index) {
        let neighborIndex = index-1;
        while (!baseTableConfig[neighborIndex].visible && neighborIndex >= 0) {
            neighborIndex--;
        }
        return neighborIndex;
    }

    saveColumnPersonalization(personalizationKey: string, columnConfig: ColumnConfig[]) {
        let tableConfig = this.loadPersonalization(personalizationKey);
        if(!tableConfig) {
            tableConfig = new TableConfig();
        }
        delete tableConfig.columns;
        tableConfig.columns = columnConfig;
        this.savePersonalization(personalizationKey, tableConfig);
    }

    saveFilterPersonalization(personalizationKey: string, filterConfig: FilterConfig[]) {
        let tableConfig = this.loadPersonalization(personalizationKey);
        if(!tableConfig) {
            tableConfig = new TableConfig();
        }
        tableConfig.filters = filterConfig;
        this.savePersonalization(personalizationKey, tableConfig);
    }

    getColumnPersonalization(personalizationKey): ColumnConfig[] {
        const tableConfig = this.loadPersonalization(personalizationKey);
        if(tableConfig) {
            return this.loadPersonalization(personalizationKey).columns;
        }else {
            return [];
        }
    }

    getFilterPersonalization(personalizationKey): FilterConfig[] {
        const tableConfig = this.loadPersonalization(personalizationKey);
        if(tableConfig) {
            return this.loadPersonalization(personalizationKey).filters;
        } else {
            return [];
        }
    }

    savePersonalization(personalizationKey: string, baseTableConfig) {
        localStorage.setItem(personalizationKey, JSON.stringify(baseTableConfig));
    }

    loadPersonalization(personalizationKey): TableConfig {
        return JSON.parse(localStorage.getItem(personalizationKey));
    }

}
