import {Component, Input, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from '@angular/material/snack-bar';
import {NliKeycloakAuthService} from "@next-level-integration-private/nli-keycloak-auth";
import {Constants} from "../../../core/common/constants";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  clientName: string;
  isErrorsRole = false;
  systemMessagesPath = environment.systemMessagesPath;
  serverInfoPath = environment.serverInfoPath;
  @Input() showImage = true;
  activateUserMessages = environment.activateUserMessages;
  systemName = environment.systemName;

  constructor(public snackBar: MatSnackBar,
              private authService: NliKeycloakAuthService, public dialog: MatDialog) {
    this.isErrorsRole = this.authService.hasRole(Constants.B2B_ROLE_ERRORS);

    const grantedScopes = authService.getGrantedScopesNames();
    this.clientName = grantedScopes.length > 0 && grantedScopes[0] !== 'none' ? grantedScopes[0] : '';

    const bodyStyles = document.body.style;
    bodyStyles.setProperty('--background-color', environment.backgroundColor);
  }

  ngOnInit() {
  }

}
