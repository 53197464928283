import {Component, Inject, OnInit} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PersonalizationService} from "../personalization.service";
import {TableConfig} from "../table-config";


@Component({
    selector: 'nli-personalization-upload-dialog',
    templateUrl: './personalization-upload-dialog.component.html',
    styleUrls: ['./personalization-upload-dialog.component.css']
})
export class PersonalizationUploadDialogComponent implements OnInit {

    uploadDocument: File;
    selectedFileName: string;
    form: FormGroup;
    personalizationKey: string;

    constructor(private readonly translate: TranslateService,
                private readonly snackBar: MatSnackBar,
                @Inject(MAT_DIALOG_DATA) private readonly data,
                private readonly formBuilder: FormBuilder,
                private personalizationService: PersonalizationService,
                private readonly dialogRef: MatDialogRef<PersonalizationUploadDialogComponent>) {
        this.personalizationKey = data.personalizationKey;
        this.form = this.formBuilder.group({
            inputFile: ['', [Validators.required]]
        });
    }

    ngOnInit() {
    }

    onSelectedFile(event) {
        this.uploadDocument = <File>event.target.files[0];
        this.selectedFileName = '';
        if (this.uploadDocument !== null) {
            this.selectedFileName = this.uploadDocument.name;
        }
    }

    save() {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            const personalization: { string: TableConfig[] } = JSON.parse(fileReader.result.toString());
            this.personalizationService.savePersonalization(this.personalizationKey, personalization);
            this.dialogRef.close(personalization);
        };
        fileReader.readAsText(this.uploadDocument);
    }
}
