import {Component} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-show-status',
    templateUrl: './show-status.component.html',
    styleUrls: ['./show-status.component.scss']
})
export class ShowStatusComponent {

    constructor(public snackBar: MatSnackBar) {
    }

  // this function will open up snackbar on top right position with custom background color (defined in css)
  openSnackBar(message: string, action: string, className: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [className],
    });
  }

  openStatusSnackbar(message: string, action?: string) {
    this.snackBar.open(message, action, {
      duration: 5000
    });
  }
}
