import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {NliKeycloakAuthService} from "@next-level-integration-private/nli-keycloak-auth";

@Injectable()
export class LoginRedirectService {

  constructor(private readonly router: Router, private readonly authenticationService: NliKeycloakAuthService) {

  }

  isLoggedIn() {
    return this.authenticationService.isLoggedIn();
  }

  routeToLoginTarget() {
    this.router.navigate(['home/dashboard']);
  }


}
