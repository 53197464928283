import {Injectable} from "@angular/core";
import {Worklist} from "../../../core/models/worklist.model";
import {NavigationEnd, Router} from "@angular/router";
import {Subject} from "rxjs/Subject";
import {SnackbarMessagesService} from "@next-level-integration/nli-core";
import {MessageService} from "../../../core/apis/message.service";

@Injectable()
export class WorklistService {

  private showWorklistBtn: Subject<boolean> = new Subject();

  private showWorklistChange: Subject<boolean> = new Subject();

  private selectedWorklistChange: Subject<Worklist> = new Subject();

  private validWorklistData: Subject<Worklist[]> = new Subject();

  private invalidWorklistData: Subject<Worklist[]> = new Subject();

  constructor(private router: Router,
              private readonly snackbarMessagesService: SnackbarMessagesService,
              private readonly messageService: MessageService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if ('/b2b/messagemonitor' !== event.url) {
          this.showWorklistChange.next(false);
          this.showWorklistBtn.next(false);
        } else {
          this.showWorklistBtn.next(true);
        }
      }
    });
  }

  public getShowWorklistBtn(): Subject<boolean> {
    return this.showWorklistBtn;
  }

  public getSelectedWorklistChange(): Subject<Worklist> {
    return this.selectedWorklistChange;
  }

  public getShowWorklistChange(): Subject<boolean> {
    return this.showWorklistChange;
  }

  public openWorklist(showWorklist: boolean) {
    this.showWorklistBtn.next(!showWorklist);
    this.showWorklistChange.next(showWorklist);
  }

  public selectWorklist(worklistData: any) {
    this.selectedWorklistChange.next(worklistData);
  }

  public getWorklistData() {
    this.messageService.getWorklistData().subscribe(
      (data: Worklist[]) => {
        if (data.length) {
          const invalidWorklist = data.filter(workList => workList.invalid === true);
          this.validWorklistData.next(data);
          this.invalidWorklistData.next(invalidWorklist);
        } else {
          this.snackbarMessagesService.showClosableSnackbar('messageMonitor.worklist.emptyWorklistMsg', 2000);
        }
      },
      error => this.snackbarMessagesService.showClosableSnackbar('messageMonitor.worklist.failedWorklistMsg' + error)
    );
  }

  public getValidWorklist(): Subject<Worklist[]> {
    return this.validWorklistData;
  }

  public getInvalidWorklist(): Subject<Worklist[]> {
    return this.invalidWorklistData;
  }
}
