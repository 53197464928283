import {Component, Inject, OnInit} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {BaseConfig} from "../table-config";
import {PersonalizationService} from "../personalization.service";

@Component({
  selector: 'nli-personalization-dialog',
  templateUrl: './personalization-dialog.component.html',
  styleUrls: ['./personalization-dialog.component.css']
})
export class PersonalizationDialogComponent implements OnInit {

  baseConfig: BaseConfig[];
  personalizationKey: string;
  title: string;
  isRestore = false;

  constructor(private readonly translate: TranslateService,
              private readonly snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) private readonly data,
              private readonly dialog: MatDialog,
              private readonly dialogRef: MatDialogRef<PersonalizationDialogComponent>,
              private readonly personalizationService: PersonalizationService) {
    this.baseConfig = data.properties;
    this.personalizationKey = data.personalizationKey;
    this.title = data.title;
  }

  ngOnInit() {
  }

  drop(event: CdkDragDrop<string[]>) {
    if((this.baseConfig[event.previousIndex].isReorderingAllowed === undefined || this.baseConfig[event.previousIndex].isReorderingAllowed)
    && (this.baseConfig[event.currentIndex].isReorderingAllowed === undefined || this.baseConfig[event.currentIndex].isReorderingAllowed)) {
      moveItemInArray(this.baseConfig, event.previousIndex, event.currentIndex);
    }
  }

  save() {
    const result = {
      isRestore: this.isRestore,
      config: this.baseConfig
    };
    this.dialogRef.close(result);
  }

  restoreDefaults() {
    this.isRestore = true;
    Object.assign(this.baseConfig, this.personalizationService.getDefaultConfig(this.personalizationKey));
  }
}
