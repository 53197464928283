import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MessageTableColumnConfig} from "../../../core/models/message.model";
import {DateRangePickerSelection} from "../date-range-picker/date-range-picker-selection";
import {ReplaySubject} from "rxjs/ReplaySubject";
import {Observable} from "rxjs/Observable";
import {ColumnPossibleValue, ColumnQuickConfig, QuickFilterType} from "../../../core/models/quick-config.model";

@Component({
  selector: 'b2b-quick-config-menu',
  templateUrl: './quick-config.component.html',
  styleUrls: ['./quick-config.component.scss']
})
export class QuickConfigComponent implements OnInit, AfterViewInit {
  @ViewChild("searchInput", {static: false}) searchInput: ElementRef;
  @Input() config: MessageTableColumnConfig;
  @Input() quickFilterSubject: ReplaySubject<MessageTableColumnConfig>;
  @Input() possibleValuesObservable: Observable<ColumnPossibleValue[]>;

  filter!: ColumnQuickConfig;
  possibleValues: ColumnPossibleValue[] = [];
  filteredPossibleValues: ColumnPossibleValue[];
  searchTerm: string;
  allChecked = false;

  FilterType = QuickFilterType;

  constructor() {
  }

  ngOnInit(): void {
    this.filter = this.config.quickConfig;
    this.searchTerm = this.filter.expression;
    if (this.filter.filterType !== QuickFilterType.DATE && this.possibleValuesObservable) {
      this.possibleValuesObservable.subscribe(values => {
        this.sort(values);
        this.possibleValues.push({name: 'EMP', value: 'EMP'});
        this.possibleValues.push(...values);
        this.filteredPossibleValues = this.possibleValues;
        this.allChecked = this.filter.values.length === this.possibleValues.length;
        this.filterPossibleValues({}, false);
      });
    }
  }

  private sort(values: ColumnPossibleValue[]): void {
    values.sort((v1, v2) => {
      if (this.filter.values.includes(v1.value)) {
        return -1;
      } else if (this.filter.values.includes(v2.value)) {
        return 1;
      }
      return v1.name.localeCompare(v2.name);
    });
  }

  ngAfterViewInit(): void {
    if (this.filter.filterType === QuickFilterType.EXPRESSION || this.filter.filterType === QuickFilterType.SELECT_WITH_EXPRESSION) {
      Observable.fromEvent(this.searchInput.nativeElement, 'keyup')
        .debounceTime(300)
        .subscribe(event => this.filterPossibleValues(event, true));
    }
  }

  onCheckAll(event, value) {
    if (event.checked) {
      this.filter.values = this.filteredPossibleValues.map(pv => pv.value);
    } else {
      this.filter.values = [];
    }
    this.allChecked = event.checked;
    this.emitFilter();
  }

  onCheckChange(event, value) {
    if (event.checked) {
      this.filter.values.push(value);
    } else {
      this.filter.values = this.filter.values.filter(v => v !== value);
    }
    this.allChecked = this.filter.values.length === this.possibleValues.length;
    this.emitFilter();
  }

  filterPossibleValues(event, update: boolean) {
    if (this.filter.filterType === QuickFilterType.DATE || this.filter.filterType === QuickFilterType.EXPRESSION) {
      if (update) {
        this.filter.expression = this.searchTerm;
        this.quickFilterSubject.next(this.config);
      }
      return;
    }

    if (!this.searchTerm || this.searchTerm.trim().length === 0) {
      this.filteredPossibleValues = this.possibleValues;
    } else {
      try {
        const not = this.searchTerm.startsWith("!");
        const regex = new RegExp(this.searchTerm.replace("!", ""), 'i');
        this.filteredPossibleValues = this.possibleValues
          .filter(pv => (not ? !pv.name.match(regex) && !pv.value.match(regex) : pv.name.match(regex) || pv.value.match(regex))
            || pv.name.includes(this.searchTerm));
      } catch (error) {
        console.log(error);
      }
    }

    if (update) {
      if (this.filteredPossibleValues.length !== this.possibleValues.length) {
        this.filter.values = this.filteredPossibleValues.map(pv => pv.value);
      } else {
        this.filter.values = [];
      }
      this.filter.expression = this.searchTerm;
      this.quickFilterSubject.next(this.config);
    }

    if (this.filteredPossibleValues.length > 0) {
      this.allChecked = this.filter.values.length === this.filteredPossibleValues.length;
    } else {
      this.allChecked = false;
    }
  }

  toggleSort(asc: boolean) {
    this.filter.asc = this.filter.asc === undefined || asc !== this.filter.asc ? asc : undefined;
    this.emitFilter();
  }

  dateSelected(event: DateRangePickerSelection) {
    this.filter.dateRange = event;
    this.emitFilter();
  }

  emitFilter() {
    this.quickFilterSubject.next(this.config);
  }

}

