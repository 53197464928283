import {Component, HostListener} from "@angular/core";
import {TranslateService} from '@ngx-translate/core';
import {timer} from "rxjs/internal/observable/timer";
import {takeUntil} from "rxjs/operators";
import {ErrorModel} from "@next-level-integration/nli-core";
import {Constants} from "./core/common/constants";
import {ErrorStoreService} from "@next-level-integration/list-errors";
import {Subject} from "rxjs/Subject";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  unsubscribe$ = new Subject();

  constructor(
    private translateService: TranslateService,
    private errorStoreService: ErrorStoreService) {
    this.errorListHandling();
  }

  async ngOnInit() {
    await this.translateService.use(localStorage.getItem('selectedLang')).toPromise<void>();
  }

  public errorListHandling() {
    timer(1000, 2000).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      const errorList: [] = this.getItemFromSessionStorage() ?? [];
      this.removeItemFromSessionStorage();
      errorList.map((item: any) => {
        this.errorStoreService.addError(
          new ErrorModel(
            item?.status && item?.statusText ? `${item?.status} ${item?.statusText}` : '',
            item.message, ''
          )
        );
      });
    });
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    this.removeItemFromSessionStorage();
  }

  private getItemFromSessionStorage() {
    return JSON.parse(sessionStorage.getItem(Constants.ERROR_LIST_KEY));
  }

  private removeItemFromSessionStorage() {
    return sessionStorage.removeItem(Constants.ERROR_LIST_KEY);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
