<div class="datepicker" (keydown.Tab)="$event.stopPropagation()">
  <form #form="ngForm">
    <mat-grid-list cols="2" rowHeight="350" [style.width.px]="600" (click)="$event.stopPropagation()">
      <mat-grid-tile>
        <div class="date-range-container">
          <!--Title: choose period-->
          <div class="date-range-header">
          <span>
            {{'messageMonitor.datePicker.selectPeriod'| translate}}
          </span>
          </div>
          <!--Input: period-->
          <div class="input-container">
            <div>
              <label class="input-title">{{'messageMonitor.datePicker.period'| translate}}</label>
            </div>
            <div class="preset-selection">
              <mat-form-field appearance="fill" class="input preset-selection">
                <mat-select name="preset" [(ngModel)]="preset" #period="ngModel"
                            (selectionChange)="presetChange($event)">
                  <mat-option value="allTime">{{'messageMonitor.datePicker.allTime' | translate}}</mat-option>
                  <mat-option value="today">{{'messageMonitor.datePicker.today' | translate}}</mat-option>
                  <mat-option value="yesterday">{{'messageMonitor.datePicker.yesterday' | translate}}</mat-option>
                  <mat-option
                    value="todayAndYesterday">{{'messageMonitor.datePicker.todayAndYesterday' | translate}}</mat-option>
                  <mat-option value="last7Days">{{'messageMonitor.datePicker.last7Days' | translate}}</mat-option>
                  <mat-option value="last30Days">{{'messageMonitor.datePicker.last30Days' | translate}}</mat-option>
                  <mat-option value="last6Months">{{'messageMonitor.datePicker.last6Months' | translate}}</mat-option>
                  <mat-option value="lastYear">{{'messageMonitor.datePicker.lastYear' | translate}}</mat-option>
                  <mat-option value="individual">{{'messageMonitor.datePicker.individual' | translate}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <!--Input: start-->
          <div class="input-container">
            <div>
              <label class="input-title">{{'messageMonitor.datePicker.from'| translate}}</label>
            </div>
            <!--Input: startDate-->
            <div class="date-container">
              <mat-form-field appearance="fill" class="input">
                <input name="fromDate" [(ngModel)]="fromDate" matInput
                       #startDate="ngModel"
                       required
                       [pattern]="datePattern" (ngModelChange)="manualChange()" (blur)="onBlur()"
                       placeholder="{{'messageMonitor.datePicker.startDate'| translate}}">
                <mat-error
                  *ngIf="startDate.errors?.pattern">{{'messageMonitor.datePicker.invalidDateFormat'| translate}}</mat-error>
              </mat-form-field>
            </div>
            <!--Input: startTime-->
            <div class="time-container">
              <mat-form-field appearance="fill" class="input">
                <input name="fromTime" [(ngModel)]="fromTime" matInput
                       #startTime="ngModel"
                       required
                       [pattern]="timePattern" (ngModelChange)="manualChange()" (blur)="onBlur()"
                       placeholder="{{'messageMonitor.datePicker.startTime'| translate}}">
                <mat-error
                  *ngIf="startTime.errors?.pattern">{{'messageMonitor.datePicker.invalidTimeFormat'| translate}}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <!--Input: end-->
          <div class="input-container">
            <div>
              <label class="input-title">{{'messageMonitor.datePicker.to'| translate}}</label>
            </div>
            <!--Input: endDate-->
            <div class="date-container">
              <mat-form-field appearance="fill" class="input">
                <input name="toDate" [(ngModel)]="toDate" matInput
                       #endDate="ngModel"
                       required [pattern]="datePattern"
                       (ngModelChange)="manualChange()" (blur)="onBlur()"
                       placeholder="{{'messageMonitor.datePicker.endDate'| translate}}">
                <mat-error
                  *ngIf="endDate.errors?.pattern">{{'messageMonitor.datePicker.invalidDateFormat'| translate}}</mat-error>
                <mat-error
                  *ngIf="endDate.errors?.invalid">{{'messageMonitor.datePicker.endDateCannotBeforeStartDate'| translate}}</mat-error>
              </mat-form-field>
            </div>
            <!--Input: endTime-->
            <div class="time-container">
              <mat-form-field appearance="fill" class="input">
                <input name="toTime" [(ngModel)]="toTime" matInput
                       #endTime="ngModel"
                       required [pattern]="timePattern"
                       (ngModelChange)="manualChange()" (blur)="onBlur()"
                       placeholder="{{'messageMonitor.datePicker.endTime'| translate}}">
                <mat-error
                  *ngIf="endTime.errors?.pattern">{{'messageMonitor.datePicker.invalidTimeFormat'| translate}}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="calendar-container">
          <mat-calendar #calendar
                        class="calendar"
                        [startAt]="selectedDateRange?.start"
                        [selected]="selectedDateRange"
                        (selectedChange)="selectedChange($event,true)">
          </mat-calendar>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <!--Submit-Button-->
    <div class="btn-container" align="right">
      <button class="clear-btn" type="submit" mat-button
              (click)="clear();$event.stopPropagation();"
              color="primary">{{'messageMonitor.datePicker.clear'| translate}}</button>
      &nbsp;
      <button class="confirm-btn" type="submit" mat-raised-button [disabled]="form.invalid"
              (click)="confirm()"
              color="accent">{{'messageMonitor.datePicker.confirm'| translate}}</button>

    </div>
  </form>
</div>

