import {Directive, Input} from '@angular/core';
import {MatMenuPanel, MatMenuTrigger} from '@angular/material/menu';

// for disabling click on menu trigger,
// check: https://stackoverflow.com/questions/54766153/angular-material-mat-menu-disable-matmenutriggerfor
@Directive({
  selector: `[disabledMenuTriggerFor]`,
  exportAs: 'disabledMenuTrigger'
})
export class DisabledMenuTriggerDirective extends MatMenuTrigger {
  _handleClick(event: MouseEvent): void {
  }

  @Input('disabledMenuTriggerFor')
  get menu() {
    return super.menu;
  }

  set menu(menu: MatMenuPanel) {
    super.menu = menu;
  }
}
